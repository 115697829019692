import { useState, useEffect } from 'react';

const CardTextPreview = (props) => {

  const [formattedText, setFormattedText] = useState('')

  useEffect(() => {
    const rawContent = props.content_text || '';
    let formattedText = rawContent
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&nbsp;', ' ')
      .replaceAll('&rsquo;', '\'')
      .replaceAll('&mdash;', '-')
      setFormattedText(formattedText);
  }, [props.email_id]);

  return (
    <div className="w-full border-l border-base-300">
      <textarea rows="40" className="w-full justify-center p-4" defaultValue={ formattedText } readOnly={ true } />
    </div>
  );
};

export default CardTextPreview;
