import { useState } from 'react';

const ProxySearchToolBar = (props) => {

  const [referenceValue, setReferenceValue] = useState('');

  const handleReferenceLookup = () => {
    console.debug('Handle Reference Code Lookup: ', referenceValue);
    props.onSearchRequest(referenceValue)
  }

  return (
    <div className="w-full p-4">
      <input 
        className="input input-bordered input-rounded border-slate-800 w-3/4"
        name="reference" 
        placeholder="Proxy Reference #"
        onChange={ (event) => setReferenceValue(event.target.value) } />
      <button className="rounded btn btn-primary" onClick={ handleReferenceLookup }>Lookup</button>
    </div>
  )
}


export default ProxySearchToolBar;
