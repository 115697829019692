
export const SanKeyListScrubResult = (graphData={}) => {

  // const processed = parseInt(graphData.records_processed);
  const total_net_new = parseInt(graphData.net_new_passed) + parseInt(graphData.net_new_failed);
  const total_known = parseInt(graphData.known_passed) + parseInt(graphData.known_failed);
  const total_errors = parseInt(graphData.records_error);

  const graphDataSet = () => {
    let data = [];

      if (total_net_new > 0 && total_known > 0 && total_errors > 0) {
        data = [
          ['Imported List', 'Net New', total_net_new],
          ['Imported List', 'Known Propsects', total_known],
          ['Imported List', 'Errors', total_errors],
          ['Known Propsects', 'Passed Quality Tests', parseInt(graphData.known_passed)],
          ['Known Propsects', 'Failed Quality Tests', parseInt(graphData.known_failed)],
          ['Net New', 'Passed Quality Tests', parseInt(graphData.net_new_passed)],
          ['Net New', 'Failed Quality Tests', parseInt(graphData.net_new_failed)],
          ['Errors', 'Failed Quality Tests', total_errors]
        ]
      }

      if (total_net_new > 0 && total_known > 0 && total_errors == 0) {
        data = [
          ['Imported List', 'Net New', total_net_new],
          ['Imported List', 'Known Prospects', total_known],
          ['Net New', 'Passed Quality Tests', parseInt(graphData.net_new_passed)],
          ['Net New', 'Failed Quality Tests', parseInt(graphData.net_new_failed)],
          ['Known Prospects', 'Passed Quality Tests', parseInt(graphData.known_passed)],
          ['Known Prospects', 'Failed Quality Tests', parseInt(graphData.known_failed)]
        ]
      }

      if (total_net_new === 0 && total_known > 0 && total_errors > 0) {
        data = [
          ['Imported List', 'Known Propsects', total_known],
          ['Imported List', 'Errors', total_errors],
          ['Known Propsects', 'Passed Quality Tests', parseInt(graphData.known_passed)],
          ['Known Propsects', 'Failed Quality Tests', parseInt(graphData.known_failed)],
          ['Errors', 'Failed Quality Tests', total_errors]
        ]
      }

      if (total_net_new === 0 && total_known > 0 && total_errors === 0) {
        data = [
          ['Imported List', 'Known Propsects', total_known],
          ['Known Propsects', 'Passed Quality Tests', parseInt(graphData.known_passed)],
          ['Known Propsects', 'Failed Quality Tests', parseInt(graphData.known_failed)],
        ]
      }
        
    return data;
  };
  
  const options = {
    title: {
      text: graphData.title || 'List Scrub Job Results'
    },
    series: [{
      type: 'sankey',
      data: graphDataSet(),
    }],
    credits: {
      enabled: false,
    },
  }
  return options;
}
