import PropTypes from 'prop-types';

const DashboardCard = (props) => {

  const layout = props && props.layout ? props.layout : 'default'

  const horizontalCard = () => {
    return (
      <div className="grid grid-cols-2">
        <div className="text-xl">{ props.title }</div>
        <div className="text-4xl">{ props.value }</div>
      </div>
    );
  }

  const defaultCard = () => {
    return (
      <div className="text-center border rounded-lg p-4 border-slate-600">
        <div className="text-2xl font-light">{ props.title }</div>
        <div className="text-5xl font-bold">{ props.value || 0 }</div>
      </div>
    )
  }

  if (layout === 'horizontal') {
    return horizontalCard();
  } else {
    return defaultCard();
  }

};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  layout: PropTypes.string,
};

DashboardCard.defaultProps = {
  title: '',
  value: 0,
  layout: 'default'
};

export default DashboardCard;
