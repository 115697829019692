import axios from 'axios';
import {
  API_ENDPOINT, 
  API_REQUEST_HEADERS
} from './ServiceHelpers';

// export const createUrlLink = async (authState, id) => {
//   console.debug('fetchPromotion', authState.accessToken);
//   return axios.get(`${API_ENDPOINT}/url_links/`, API_REQUEST_HEADERS(authState.accessToken))
//     .then(resp => {
//       console.debug("SERVER_RESPONSE: ", resp.data);
//       return resp.data;
//     })
//     .catch(err => {
//       throw new Error('Unable to fetch campaigns', { cause: err });
//     })
// }

export const createUrlLink = async (authState, data) => {
  return axios.post(`${API_ENDPOINT}/url_links/`, data, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      console.debug("SERVER_RESPONSE: ", resp.data);
      return resp.data;
    })
    .catch(err => {
      throw new Error('Unable to create url link for promotion', { cause: err });
    })
}

export const updateUrlLink = async (authState, data) => {
  console.debug('updateUrlLink: ', data);
  return axios.post(`${API_ENDPOINT}/promotions/${data.id}/links`, data, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      console.debug("SERVER_RESPONSE: ", resp.data);
      return resp.data;
    })
    .catch(err => {
      throw new Error('Unable to create url link for promotion', { cause: err });
    })
}
