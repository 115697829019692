// import { useState } from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from '../../shared/DropDownMenu';
import { CAPDB_FIELDS } from '../../../service/UtilityFunctions';

const FieldMappingRow = (props) => {

  const handleChange = (event) => {
    props.onChange({
      csv_key: props.item,
      capdb_key: event.target.value
    });
  }

  return (
    <div className="grid grid-cols-2 space-y-4 border-b pt-2 pb-2">
      <div className="pt-6 text-xl">{ props.item }</div>
      <div>
        <DropDownMenu
          options={ CAPDB_FIELDS() }
          onChange={ handleChange }  
        />
      </div>
    </div>
  )
};

FieldMappingRow.propTypes = {
  item: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
FieldMappingRow.defaultProps = {
  item: '',
  onChange: () => {},
};

export default FieldMappingRow;
