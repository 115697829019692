import { useState } from 'react';

const EmailAnalyzerSearchBox = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [actionValue, setActionValue] = useState('');

  const handleActionChange = (event) => {
    const val = event.target.value;
    setActionValue(val);
  }

  const handleInputChange = (event) => {
    const val = event.target.value;
    setSearchQuery(val);
  }

  const handleGoClick = async () => {
    if (actionValue === 'import') {
      props.onImport(searchQuery);
    }

    if (actionValue ==='search') {
      props.onSearch(searchQuery);
    }
  }

  return (
    <div className="float-right">
      <div className="form-control w-full float-right">
        <div className="input-group">
          <input 
            type="text" 
            placeholder="Search Keyword or Marketo URL" 
            className="input input-bordered w-3/4"
            onChange={ handleInputChange }  
          />
          <select className="select select-bordered" onChange={ handleActionChange } defaultValue="">
            <option value="">Pick Action</option>
            <option value="search">Search</option>
            <option value="import">Import</option>
          </select>
          <button className="btn btn-primary" onClick={ handleGoClick }>Go</button>
        </div>
      </div>
    </div>
  )
}

export default EmailAnalyzerSearchBox;
