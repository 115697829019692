const UIContextIndicator = (props) => {
  const message = props.message ? props.message : 'Loading...'
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="text-3xl">{ message }</div>
    </div>
  )
}

export default UIContextIndicator;
