const NotAuthorized = (props) => {
  
  const message = props.message ? props.message : 'User not Auth'
  return (
    <div className="w-full mt-48 flex items-center justify-center">
      <p>Your user is not authorized to perform this action.</p>
      <p>Please contact your manager for further assistance</p>
    </div>
  );

}

export default NotAuthorized;
