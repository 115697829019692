import axios from 'axios';
import {
  API_ENDPOINT, 
  API_REQUEST_HEADERS
} from './ServiceHelpers';

export const fetchPromotion = async (authState, id) => {
  console.debug('fetchPromotion', authState.accessToken);
  return axios.get(`${API_ENDPOINT}/promotions/${id}`, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      console.debug("SERVER_RESPONSE: ", resp.data);
      return resp.data;
    })
    .catch(err => {
      throw new Error('Unable to fetch campaigns', { cause: err });
    })
}

export const createPromotion = async (authState, data) => {
  return axios.post(`${API_ENDPOINT}/promotions/`, data, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      console.debug("SERVER_RESPONSE: ", resp.data);
      return resp.data;
    })
    .catch(err => {
      throw new Error('Unable to create promotion', { cause: err });
    })
}
