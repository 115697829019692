import { useState, useEffect } from 'react';
// import { useOutletContext } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import NotificationSubscriptions from '../components/user-settings/notification-subscriptions';
import MfaConfig from '../components/mfa-config';

const SettingsPage = (props) => {

  console.debug('SettingsPage: ', props);

  // const [authContext, setAuthContext] = useOutletContext();

  return (
    <div>
      <h1 className="text-3xl p-4 m-0">Settings Page</h1>
      <NotificationSubscriptions {...props} />
      <MfaConfig {...props} />
    </div>
  );

}

export default SettingsPage;
