import PropTypes from 'prop-types';

const TableUserLists = (props) => {

  const tableRows = props.userJobs.map((job, idx) => {

    const d = new Date(job.created_at);
    const jobDate = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' }).format(d);

    return (
      <tr key={ idx } onClick={ () => props.handleJobSelect(job) } className=" py-2 px-2 hover:cursor-pointer text-sm hover:bg-blue-200 hover:text-black">
        <td>{ job.id }</td>
        <td>{ jobDate }</td>
        <td>{ job.task_name }</td>
        <td>{ job.username }</td>
        <td>{ job.task_status }</td>
      </tr>
    );
  });

  return (
    <div className="overflow-y-scroll max-h-screen" >
      <table className="w-full table-auto border-collapse">
        <thead>
          <tr className="bg-slate-300">
            <th>Job ID</th>
            <th>Date</th>
            <th>Task Name</th>
            <th>User</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed divide-slate-600">
          { tableRows }
        </tbody>
      </table>
    </div>
  )
}

TableUserLists.propTypes = {
  userJobs: PropTypes.array.isRequired,
  handleJobSelect: PropTypes.func.isRequired,
};

TableUserLists.defaultProps = {
  userJobs: [],
  handleJobSelect: () => {},
};

export default TableUserLists;
