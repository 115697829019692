import axios from 'axios';
import { API_ENDPOINT, API_REQUEST_HEADERS } from './ServiceHelpers';
// import { useOktaAuth } from '@okta/okta-react';


const fetchValidationRuleList = async (jwt, filter) => {
  let qs = ''
  const urlSearchParams = {};

  console.debug('Filters Passed to fetch Validation: ', filter);

  if (filter !== null && filter !== 'undefined') {

    if (filter && filter.rule) {
      qs += `&filter=${filter.rule}`
      urlSearchParams.filter = filter.rule
    }

    if (filter && filter.rule_type) {
      qs += `&filter=${filter.rule_type}`
      urlSearchParams.filter = filter.rule_type
    }

    if (filter && filter.query !== '') {
      qs += `&query=${filter.query}`;
      urlSearchParams.query = filter.query;
    }

    if (filter.page && filter.page > 0) {
      qs += `&page=${filter.page}`;
      urlSearchParams.page = filter.page;
      urlSearchParams.max = filter.max;
    } 

  }

  const cleanQS = qs.toString();
  const queryString = cleanQS === '' ? '' : `?${cleanQS}`
  const url = `${API_ENDPOINT}/validation_rules${queryString}`;

  console.debug('Sending HTTP Request: ', url);

  return await axios.get(url, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
}

const createValidationRule = async (jwt, payload) => {
  const url = `${API_ENDPOINT}/validation_rules`;
  return await axios.post(url, payload, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      console.debug('CREATE_VALIDATION_RULE_RESPONSE: ', resp.data);
      return resp.data;
    })
    .catch(err => {
      throw new Error('Failed to create validation rule', { cause: err });
    });
}

const saveValidationRule = async (jwt, payload) => {
  const url = `${API_ENDPOINT}/validation_rules/${payload.id}`;
  return await axios.patch(url, payload, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      console.debug('SAVE_VALIDATION_RULE_RESPONSE: ', resp.data);
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
}

const ValidationRuleService = {
  fetchValidationRuleList,
  createValidationRule,
  saveValidationRule
};

export default ValidationRuleService;
