import { useState } from "react";

const AdNetworkPromoForm = (props) => {
  return (
    <div className="m-4">
      <h1 className="text-3xl">Ad Network Promotion</h1>
      <p>Use this promotion type to create a promotion for one of the following platforms.</p>
      { JSON.stringify(props) }
    </div>
  )
}

export default AdNetworkPromoForm;
