import { useEffect, useState } from "react";
import ValidationRuleService from "../../service/ValidationRuleService";
import { useOktaAuth } from '@okta/okta-react';

const ValidationRuleFormModal = (props) => {

  const oktaAuth = useOktaAuth();

  const authState = oktaAuth.authState;

  const [ruleType, setRuleType] = useState('');
  const [ruleCondition, setRuleCondition] = useState('CONTAINS');
  const [ruleAction, setRuleAction] = useState('DENY');
  const [ruleValue, setRuleValue] = useState('');

  const [lawpayChecked, setLawpayChecked] = useState(false);
  const [cpaChargeChecked, setCpaChargeChecked] = useState(false);
  const [clientPayChecked, setClientPayChecked] = useState(false);
  const [affiniPayChecked, setAffiniPayChecked] = useState(false);
  const [myCaseChecked, setMyCaseChecked] = useState(false);
  const [casePeerChecked, setCasePeerChecked] = useState(false);
  const [docketwiseChecked, setDocketwiseChecked] = useState(false);

  useEffect(() => {
    if (props.rule != null) {
      setRuleType(props.rule.rule_type || 'SERVICE_PROVIDER_DOMAIN');
      setRuleCondition(props.rule_condition || 'CONTAINS');
      setRuleValue(props.rule.rule_value);
      setRuleAction(props.rule.rule_action);
      const brands = props.rule.rule_brands;
      setLawpayChecked(brands.includes('lawpay'));
      setCpaChargeChecked(brands.includes('cpacharge'));
      setClientPayChecked(brands.includes('clientpay'));
      setAffiniPayChecked(brands.includes('affinipay'));
      setMyCaseChecked(brands.includes('mycase'));
      setCasePeerChecked(brands.includes('casepeer'));
      setDocketwiseChecked(brands.includes('docketwise'));
    }
  }, [props.rule])

  if (props.rule === null || props.rule === 'undefined') {
    return ''
  } else {
    const modalOpenStyle = props.isOpen === true ? 'modal-open' : '';

    const brandedString = () => {
      const checkedBrands = [];
      if (lawpayChecked)    checkedBrands.push('lawpay');
      if (cpaChargeChecked) checkedBrands.push('cpacharge');
      if (clientPayChecked) checkedBrands.push('clientpay');
      if (affiniPayChecked) checkedBrands.push('affinipay');
      if (myCaseChecked)    checkedBrands.push('mycase');
      if (casePeerChecked)  checkedBrands.push('casepeer');
      if (docketwiseChecked) checkedBrands.push('docketwise');

      const str = checkedBrands.join(',')
      return str;
    }

    const formPayload = () => {
      return {
        id: props.rule.id || null,
        rule_type: ruleType,
        rule_condition: ruleCondition,
        rule_value: ruleValue,
        rule_action: 'DENY',
        rule_brands: brandedString()
      }
    }

    const handleRuleTypeChange = (event) => {
      const val = event.target.value;
      setRuleType(val);
    }

    const handleRuleConditionChange = (event) => {
      const val = event.target.value;
      setRuleCondition(val);
    }

    const handleRuleValueChange = (event) => {
      const val = event.target.value;
      setRuleValue(val);
    }

    const handleActionChange = (event) => {
      const val = event.target.value;
      setRuleAction(val);
    }

    const handleCheckboxClick = (event) => {
      const val = event.target.value;
      if (val === 'lawpay') setLawpayChecked(!lawpayChecked);
      if (val === 'cpacharge') setCpaChargeChecked(!cpaChargeChecked);
      if (val === 'clientpay') setClientPayChecked(!clientPayChecked);
      if (val === 'affinipay') setAffiniPayChecked(!affiniPayChecked);
      if (val === 'mycase')   setMyCaseChecked(!myCaseChecked);
      if (val === 'casepeer') setCasePeerChecked(!casePeerChecked);
      if (val === 'docketwise') setDocketwiseChecked(!docketwiseChecked);
    }

    const handleSaveClick = async () => {
      const payload = formPayload();
      console.debug('FORM_PAYLOAD: ', payload);
      if (payload.id === null) {
        // Handle Create Methods
        ValidationRuleService.createValidationRule(authState.accessToken, payload)
          .then(resp => {
            // console.debug('CREATE_API_CALL_RESPONSE: ', resp);
            const data = Object.assign({}, resp, { result: 'created' });
            props.onClose(data)
          })
          .catch(err => {
            console.debug('CREATE_API_ERROR: ', err.message);
          });
      }  else {
        // Handle Updates
        return ValidationRuleService.saveValidationRule(authState.accessToken, payload)
          .then(resp => {
            // console.debug('SAVE_API_CALL_RESPONSE: ', resp);
            const data = Object.assign({}, resp, { result: 'updated' });
            props.onClose(data);
          })
          .catch(err => {
            console.debug('API_CALL_ERROR: ', err.message);
          });
        }
    }

    const cpaCheckedStatus = cpaChargeChecked ? 'checked="checked"' : '';

    return (
      <div className={ `modal ${modalOpenStyle}` } id="validation-rule-form">
        <div className="modal-box">
          <h3 className="font-semibold text-xl">Validation Rule</h3>
          <div className="p-4">

            <div className="form-group my-4">
              <label htmlFor="rule_type" className="pr-4 font-semibold">Rule Type:</label>
              <select className="select w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary" onChange={ handleRuleTypeChange } value={ ruleType }>
                <option value="SERVICE_PROVIDER_DOMAIN">Service Provider Domains</option>
                <option value="EXCLUDED_COUNTRY">Excluded Country</option>
                <option value="EXCLUDED_EMAIL_USER">Excluded Email Username</option>
                <option value="NO_SEND_TLD">Excluded TLD</option>
                <option value="TRIGGER_JOB_TITLE">Job Title Flag</option>
                <option value="TRIGGER_COMPANY_NAME">Company Name Flag</option>
              </select>
            </div>

            <div className="form-group my-4">
              <label htmlFor="rule_type" className="pr-4 font-semibold">Rule Condition:</label>
              <select className="select w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary" onChange={ handleRuleConditionChange } value={ ruleCondition }>
                <option value="CONTAINS">CONTAINS</option>
                <option value="EQUALS">Equals (Exact Match)</option>
                <option value="STARTS_WITH">STARTS_WITH</option>
                <option value="ENDS_WITH">ENDS_WITH</option>
                <option value="SINGLE_WORD">SINGLE_WORD</option>
              </select>
            </div>

            <div className="form-group my-4">
              <label htmlFor="rule_value" className="pr-4 font-semibold">Rule Value:</label>
              <input className="input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary" onChange={  handleRuleValueChange } value={ ruleValue } />
            </div>

            <div className="form-group my-4">
              <label htmlFor="rule_action" className="pr-4 font-semibold" >Rule Action</label>
              <input className="input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary" onChange={ handleActionChange } value={ ruleAction } disabled />
            </div>

            <div className="max-x-12">

              <div className="text-lg font-semibold">Apply to Brands:</div>

              <div className="form-group">
                <input id="brand-lawpay" type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="lawpay" checked={ lawpayChecked } />
                <label htmlFor="brand-lawpay" className="pl-4">LawPay</label>
              </div>

              <div className="form-group">
                <input type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="cpacharge" checked={ cpaChargeChecked } />
                <label htmlFor="rule_brands" className="pl-4">CPACharge</label>
              </div>

              <div className="form-group">
                <input type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="clientpay" checked={ clientPayChecked } />
                <label htmlFor="rule_brands" className="pl-4">ClientPay</label>
              </div>

              <div className="form-group">
                <input type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="affinipay" checked={ affiniPayChecked } />
                <label htmlFor="rule_brands" className="pl-4">Associations</label>
              </div>

              <div className="form-group">
                <input type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="mycase" checked={ myCaseChecked } />
                <label htmlFor="rule_brands" className="pl-4">MyCase</label>
              </div>
            </div>

            <div className="form-group">
              <input type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="casepeer" checked={ casePeerChecked } />
              <label htmlFor="rule_brands" className="pl-4">CASEPeer</label>
            </div>

            <div className="form-group">
              <input type="checkbox" className="checkbox checkbox-md" onChange={ handleCheckboxClick } value="docketwise" checked={ docketwiseChecked } />
              <label htmlFor="rule_brands" className="pl-4">Docketwise</label>
            </div>

          </div>
          <div className="modal-action">
            <button className="btn btn-outline" onClick={ props.onClose }>Cancel</button>
            <button className="btn btn-primary" onClick={ handleSaveClick }>Save</button>
          </div>

        </div>
      </div>
    );
  }
}

export default ValidationRuleFormModal;
