import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CardHtmlPreview from './CardHtmlPreview';
import CardTextPreview from './CardTextPreview';

const CardPreviewSummary = (props) => {

  const [htmlTabActive, setHtmlTab] = useState(true);
  const [textTabActive, setTextTab] = useState(false);

  const switchPreviewTabs = (event) => {
    const target = event.target.getAttribute('href').toString();
    setHtmlTab(false);
    setTextTab(false);
    if (target.includes('html')) setHtmlTab(true);
    if (target.includes('text')) setTextTab(true);
  }

  return (
    <div>
      <h2 className="text-3xl">Preview Email Content</h2>
      <div className="tabs mt-4">
        <Link to="#tabs-html" className={ `tab tab-lifted text-xl px-4 ${htmlTabActive ? 'tab-active' : ''}` } onClick={ switchPreviewTabs }>HTML</Link>
        <Link to="#tabs-text" className={ `tab tab-lifted text-xl px-4 ${textTabActive ? 'tab-active' : ''}` } onClick={ switchPreviewTabs }>Text</Link>
      </div>
      <div className="tab-content" id="tabs-tabContent">
        <div className={ `tab-pane pt-0 ${htmlTabActive ? 'tab-active' : 'hidden'}`} id="tabs-html" role="tabpanel">
          <CardHtmlPreview { ...props }/>
        </div>
        <div className={ `tab-pane pt-0 ${textTabActive ? 'tab-active' : 'hidden'}`} id="tabs-text" role="tabpanel">
          <CardTextPreview { ...props }/>
        </div>
      </div>
    </div>
  )
};

export default CardPreviewSummary;
