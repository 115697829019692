import { useState, useEffect } from "react";
import { dateFormat } from '../../service/UtilityFunctions';

const CampaignDetailSummary = (campaign) => {

  useEffect(() => { 
    if (campaign) {
      console.debug('NEW_CAMPAIGN_EFFECT: ', campaign.title);
    }
  }, [campaign]);


  return (
    <div>
      <h1 className="text-2xl">Summary of Campaign: { campaign.title }</h1>
      <p>{ campaign.description }</p>
      <div>{ dateFormat(campaign.launch_date) }</div>
      <div>{ dateFormat(campaign.expire_date) }</div>
    </div>
  )
}

export default CampaignDetailSummary;
