import { useState } from 'react';

const MfaConfig = (props) => {

  return (
    <div>
      <h1 className="text-4xl">MFA Configuration</h1>
    </div>
  )
}

export default MfaConfig;
