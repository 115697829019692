import DashboardCard from "../../shared/DashboardCard";

const CardEmailSummary = (props) => {
  const { email, report } = props;
  const emailName = email && email.name ? email.name : '';
  const subjectLine = email && email.subject_line ? email.subject_line : '';
  const fromEmail = email && email.from_email ? email.from_email : '';
  const replyEmail = email && email.reply_email ? email.reply_email : '';
  const mktoUrl = email && email.marketo_url ? email.marketo_url : '';
  const scanDate = email && email.scanned_at ? new Date(email.scanned_at).toLocaleString() : 'No Scan Available';

  const numberPassed = email && email.scan_test_pass ? email.scan_test_pass : 0
  const numberWarning = email && email.scan_test_warn ? email.scan_test_warn : 0
  const numberErrors = email && email.scan_test_error ? email.scan_test_error : 0

  return(
    <div>
      <h2 className="text-3xl">Email Summary</h2>

      <div className="grid grid-cols-12">
        <div className="col-span-3">Email Name:</div>
        <div className="col-span-9">{ emailName }</div>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-3">Subject Line:</div>
        <div className="col-span-9">{ subjectLine }</div>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-3">Send From:</div>
        <div className="col-span-9">{ fromEmail }</div>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-3">Reply To:</div>
        <div className="col-span-9">{ replyEmail }</div>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-3">Marketo URL:</div>
        <div className="col-span-9"><a href={ mktoUrl } target="_blank" rel="noreferrer nofollow">{ mktoUrl }</a></div>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-3">Scan Date:</div>
        <div className="col-span-9">{ scanDate }</div>
      </div>

      <div className="grid grid-cols-3 space-x-4 mt-8">
        <DashboardCard title="Passed" value={ numberPassed } />
        <DashboardCard title="Warnings" value={ numberWarning } />
        <DashboardCard title="Errors" value={ numberErrors } />
      </div>
    </div>
  );

}

export default CardEmailSummary;
