import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const DefaultLayout = () => {
  const { oktaAuth, authState } = useOktaAuth();

  if(!authState) {
    return <div>Loading...</div>;
  }

  if(!authState.isAuthenticated) {
    oktaAuth.signInWithRedirect();
  }
  
  return (
    <div>&nbsp;</div>
  )
}

export default DefaultLayout;
