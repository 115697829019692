import { useEffect, useState } from 'react';
import { dateFormat } from '../../service/UtilityFunctions';

const PromotionImageList = (props) => {

  const [imageRows, setImageRows] = useState([])

  useEffect(() => {
    if (props.images != null) {
      console.debug('IMAGES_USE_EFFECT: ', props.images);
      const rows = props.images.map(item => {
        return (
          <tr key={ item.id }>
            <td>{ item.title }</td>
            <td>{ item.description }</td>
            <td>{ item.download_url }</td>
            <td>{ item.public_url }</td>
            <td>{ dateFormat(item.created) }</td>
            <td>{ dateFormat(item.updated_at) }</td>
            <td><button className="btn btn-xs btn-primary">View</button></td>
          </tr>
        )
      });
      setImageRows(rows);
    } else {
      const noContent = () => {
        return (
          <tr>
            <td colSpan={ 6 }>This Promotion has no images attached</td>
          </tr>
        )
      }
      setImageRows(noContent());
    }
  }, [props.images]);

  const initAddImage = () => {
    console.debug('Open modal to add attach image.')
  }

  return (
    <div className="my-4">
      <div className="grid grid-cols-2 bg-slate-800 text-white px-2 p-1">
        <div><h1 className="text-xl pt-2">Promotion Images</h1></div>
        <div className="text-right py-2 pr-2"><button className="btn btn-sm btn-primary" onClick={ initAddImage }>Add Image</button></div>
      </div>
      <div>
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-slate-500 text-white p-2">
              <td className="p-2">Document Title</td>
              <td>Description</td>
              <td>Download URL</td>
              <td>Public URL</td>
              <td>Date Created</td>
              <td>Last Updated</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            { imageRows }
          </tbody>
        </table>
      </div>
    </div>
  )

}

export default PromotionImageList;
