import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import UIContextIndicator from '../components/shared/UIContextIndicator';
import ScanResultsTable from '../components/partner-verification/ScanResultsTable';
import PartnerVerificationService from '../service/PartnerVerificationService';


const PartnerVerificationPage = (props) => {

  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tabLawPayActive, setTabLawPayActive] = useState(true);
  const [tabCPAChargeActive, setTabCPAChargeActive] = useState(false);
  const [tabClientPayActive, setTabClientPayActive] = useState(false);
  const [tabAssociationsActive, setTabAssociationsActive] = useState(false);
  const [tabAffiniPayActive, setTabAffiniPayActive] = useState(false);
  const [tabMyCaseActive, setTabMyCaseActive] = useState(false);
  const [tabDocketwiseActive, setTabDocketwiseActive] = useState(false);
  const [tabCasePeerActive, setTabCasePeerActive] = useState(false);
  const [scanResults, setScanResults] = useState(null);
  const [resultsLawPay, setLawPayResults] = useState(null);
  const [resultsCpaCharge, setCpaChargeResults] = useState(null);
  const [resultsClientPay, setClientPayResults] = useState(null);
  const [resultsAffiniPay, setAffiniPayResults] = useState(null);
  const [resultsAssociations, setAssociationsResults] = useState(null);
  const [resultsMyCase, setMyCaseResults] = useState(null);
  const [resultsDocketwise, setDocketwiseResults] = useState(null);
  const [resultsCasePeer, setCasePeerResults] = useState(null);

  useEffect(() => {
    if (scanResults && scanResults.lawpay) {
      setLawPayResults(scanResults.lawpay);
    }

    if (scanResults && scanResults.cpacharge) {
      setCpaChargeResults(scanResults.cpacharge);
    }

    if (scanResults != null && scanResults.clientpay) {
      setClientPayResults(scanResults.clientpay);
    }

    if (scanResults != null && scanResults.affinipay) {
      setAffiniPayResults(scanResults.affinipay);
    }

    if (scanResults != null && scanResults.associations) {
      setAssociationsResults(scanResults.associations);
    }

    if (scanResults != null && scanResults.mycase) {
      setMyCaseResults(scanResults.mycase);
    }

    if (scanResults != null && scanResults.docketwise) {
      setDocketwiseResults(scanResults.docketwise);
    }

    if (scanResults != null && scanResults.casepeer) {
      setCasePeerResults(scanResults.casepeer);
    }
  }, [scanResults]);

  if (authState.isAuthenticated && scanResults === null) {
    PartnerVerificationService.fetchPartnerScanResults(authState.accessToken).then(resp => {
        setScanResults(resp);
        setIsLoading(false);
      })
      .catch(err => {
        console.debug('EXCEPTION_FETCH_SCAN: ', err.message);
        throw new Error('Unable to fetch partner scan results', { cause: err });
      });
  }

  const handleTabSelect = (event) => {
    event.preventDefault();

    const target = event.target.getAttribute('href');

    setTabLawPayActive(false);
    setTabCPAChargeActive(false);
    setTabClientPayActive(false);
    setTabAffiniPayActive(false);
    setTabAssociationsActive(false);
    setTabMyCaseActive(false);
    setTabDocketwiseActive(false);
    setTabCasePeerActive(false);

    if (target.includes('lawpay'))      setTabLawPayActive(true);
    if (target.includes('cpacharge'))   setTabCPAChargeActive(true);
    if (target.includes('clientpay'))   setTabClientPayActive(true);
    if (target.includes('affinipay'))   setTabAffiniPayActive(true);
    if (target.includes('associations')) setTabAssociationsActive(true);
    if (target.includes('mycase'))      setTabMyCaseActive(true);
    if (target.includes('docketwise'))  setTabDocketwiseActive(true);
    if (target.includes('casepeer'))    setTabCasePeerActive(true);
  }

  const handlePerformScan = () => {

    setIsLoading(true);
    
    let brand = '';
    if (tabLawPayActive) {
      brand = 'lawpay';
    }

    if (tabCPAChargeActive) {
      brand = 'cpacharge';
    }

    if (tabClientPayActive) {
      brand = 'clientpay';
    }

    if (tabAffiniPayActive) {
      brand = 'affinipay';
    }

    if (tabAssociationsActive) {
      brand = 'associations'
    }

    if (tabMyCaseActive) {
      brand = 'mycase';
    }

    if (tabDocketwiseActive) {
      brand = 'docketwise';
    }

    if (tabCasePeerActive) {
      brand = 'casepeer';
    }

    PartnerVerificationService.performPartnerTokenScan(authState.accessToken, brand)
      .then(resp => {
        console.debug("RESPONSE: ", resp);
        setIsLoading(false);
        setScanResults(resp);
      })
      .catch(err => {
        setErrorMessage('Unabled to scan partner tokens');
        throw new Error('Unable to scan partner tokens', { cause: err });
      });
  };

  if (isLoading) {
    return <UIContextIndicator message="Loading" />
  } else {

    return (
      <div className="w-full">
        <div className="grid grid-cols-2 m-4">
          <div><h1 className="text-3xl">Partner Token Validation</h1></div>
          <div className="text-right"><button className="btn btn-primary" onClick={ handlePerformScan }>Verify Published Tokens</button></div>
        </div>

        { errorMessage }

        <div className="tabs m-4">
          <Link to="#tabs-lawpay" className={ `tab tab-lifted text-xl px-4 ${tabLawPayActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>LawPay</Link>
          <Link to="#tabs-cpacharge" className={ `tab tab-lifted text-xl px-4 ${tabCPAChargeActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>CPACharge</Link>
          <Link to="#tabs-clientpay" className={ `tab tab-lifted text-xl ${tabClientPayActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>ClientPay</Link>
          <Link to="#tabs-affinipay" className={ `tab tab-lifted text-xl ${tabAffiniPayActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>AffiniPay</Link>
          <Link to="#tabs-associations" className={ `tab tab-lifted text-xl ${tabAssociationsActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>Associations</Link>
          <Link to="#tabs-mycase" className={ `tab tab-lifted text-xl ${tabMyCaseActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>MyCase</Link>
          <Link to="#tabs-docketwise" className={ `tab tab-lifted text-xl ${tabDocketwiseActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>Docketwise</Link>
          <Link to="#tabs-casepeer" className={ `tab tab-lifted text-xl ${tabCasePeerActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>CasePeer</Link>
        </div>
        <div className="tab-content" id="tabs-tabContent">
          <div className={ `tab-pane p-2 pt-6 ${tabLawPayActive ? 'tab-active' : 'hidden'}`} id="tabs-lawpay" role="tabpanel">
            <ScanResultsTable { ...resultsLawPay } brand="lawpay" />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabCPAChargeActive ? 'tab-active' : 'hidden'}`} id="tabs-cpacharge" role="tabpanel">
            <ScanResultsTable { ...resultsCpaCharge } brand="cpacharge" />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabClientPayActive ? 'tab-active' : 'hidden'}`} id="tabs-clientpay" role="tabpanel">
            <ScanResultsTable { ...resultsClientPay } brand="clientpay" />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabAffiniPayActive ? 'tab-active' : 'hidden'}`} id="tabs-affinipay" role="tabpanel">
            <ScanResultsTable { ...resultsAffiniPay } brand="affinipay" />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabAssociationsActive ? 'tab-active' : 'hidden'}`} id="tabs-affinipay" role="tabpanel">
            <ScanResultsTable { ...resultsAssociations } brand="associations" host="affinipayassociations" />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabMyCaseActive ? 'tab-active' : 'hidden'}`} id="tabs-mycase" role="tabpanel">
            <ScanResultsTable { ...resultsMyCase } />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabDocketwiseActive ? 'tab-active' : 'hidden'}`} id="tabs-docketwise" role="tabpanel">
            <ScanResultsTable { ...resultsDocketwise } />
          </div>
          <div className={ `tab-pane p-2 pt-6 ${tabCasePeerActive ? 'tab-active' : 'hidden'}`} id="tabs-casepeer" role="tabpanel">
            <ScanResultsTable { ...resultsCasePeer } />
          </div>
        </div>
      </div>
    );
  }

};

export default PartnerVerificationPage;
