
const brandNames = ['LawPay', 'CPACharge', 'ClientPay', 'MyCase', 'CasePeer', 'Docketwise', 'AffiniPay'];
const signUpFormIds = [1001, 1213, 1020, 1021, 1045, 2048, 1046];
const demoFormIds = [1012, 1022, 1023, 1024, 1037, 2198, 1644];
const trialFormIds = [1395, 2000]
const enterpriseForms = [1046]
const SubmissionEntryList = (props) => {

  const tableRows = props.entries.map((item, i) => {

    const rowCss = i % 2 ? 'bg-slate-200' : 'bg-blue-200';
    const submitDate = new Date(item.submit_date).toLocaleString();

    const brand = brandNames.map((b,i) => {
      console.debug("BRAND: ", b);
      console.debug("ITEM: ", item);
      if (item.page_url && item.page_url.includes(b.toLowerCase())) {
        return b;
      }
    });

    const introspectFormType = () => {
      const fid = parseInt(item.form_id, 10);
      if (signUpFormIds.includes(fid)) {
        return enterpriseForms.includes(fid) ? 'Enterprise Sign Up' : 'Sign Up'
      }

      if (demoFormIds.includes(fid)) {
          return enterpriseForms.includes(fid) ? 'Enterprise Demo' : 'Demo'
      }

      if (trialFormIds.includes(fid)) {
        return 'Trial'
      }

      return item.form_id;
    }

    const formType = introspectFormType();

    const mktoLink = `https://app-ab56.marketo.com/leadDatabase/loadLeadDetail?leadId=${item.marketo_id}`;

    return (
      <tr className={ `py-5 text-sm text-center ${rowCss}` } key={ item.reference }>
        <td className="py-2 pl-4 text-left">{ item.reference }</td>
        <td className="py-2">{ item.marketo_status === 'created' ? 'Net New' : 'Existing' }</td>
        <td className="py-2">{ brand }</td>
        <td>{ formType }</td>
        <td>{ item.page_url }</td>
        <td><a href={ mktoLink } target="_blank" rel="noreferrer">{ item.marketo_id }</a></td>
        <td>{ item.merchant_application }</td>
        <td>{ submitDate }</td>
        <td className="grid grid-cols-2 gap-1">
          <button className="btn btn-xs bg-warning text-xs border-none hover:bg-info" onClick={ () => props.onViewSummary(item) }>Summary</button>
          <button className="btn btn-xs bg-success text-xs border-none hover:bg-info" onClick={ () => props.onViewOriginal(item.reference) }>Original</button>
        </td>
      </tr>
    )
  })

  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="bg-slate-800 text-white text-center">
          <th>Reference</th>
          <th>Lead Type</th>
          <th>Brand</th>
          <th>Form Type</th>
          <th>Page URL</th>
          <th>Marketo Lead</th>
          <th>Merchant Application</th>
          <th>Submit Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="py-4">
        { tableRows }
      </tbody>
    </table>
  );
};

export default SubmissionEntryList;
