import { useState } from 'react';

const PromotionLinkForm = (props) => {

  if (props) {

    console.debug('PROPS_PROMO_LINKS: ', props);
    const [linkId, setLinkId] = useState(props.id);
    const [linkText, setLinkText] = useState(props.link_content);
    const [linkUrl, setLinkUrl] = useState(props.link_href);
    const [utmCampaign, setUtmCampaign] = useState(props.utm_campaign);
    const [utmSource, setUtmSource] = useState(props.utm_source);
    const [utmMedium, setUtmMedium] = useState(props.utm_medium);
    const [utmContent, setUtmContent] = useState(props.utm_content);
    const [partnerToken, setPartnerToken] = useState(props.partner_token);
    const [promoToken, setPromoToken] = useState(props.promo_token);
    const [demandGen, setDemandGen] = useState(props.demand_gen);

    const handleSaveLink = async () => {
      const payload = {
        id: linkId,
        promotion_id: props.promotion_id,
        link_content: linkText,
        link_href: linkUrl,
        utm_campaign: utmCampaign,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_content: utmContent,
        partner_token: partnerToken,
        promo_token: promoToken,
        demand_gen: demandGen,
      };
      
      return await props.onSave('links', payload);
    }

    return (
      <div className="grid grid-cols-1 gap-2">
        <div>
          <label htmlFor="link_text">Link Text:</label>
          <input className="input input-bordered w-full" name="link_text" onChange={ (event) => setLinkText(event.target.value) } value={ linkText } />
        </div>
        <div>
          <label htmlFor="link_text">Link URL:</label>
          <input className="input input-bordered w-full" name="link_href" onChange={ (event) => setLinkUrl(event.target.value) } value={ linkUrl } />
        </div>
        <div>
          <label htmlFor="link_text">UTM Campaign:</label>
          <input className="input input-bordered w-full" name="link_text" onChange={ (event) => setUtmCampaign(event.target.value) } value={ utmCampaign } />
        </div>
        <div>
          <label htmlFor="link_text">UTM Source:</label>
          <input className="input input-bordered w-full" name="link_text" onChange={ (event) => setUtmSource(event.target.value) } value={ utmSource } />
        </div>
        <div>
          <label htmlFor="link_text">UTM Medium:</label>
          <input className="input input-bordered w-full" name="link_text" onChange={ (event) => setUtmMedium(event.target.value) } value={ utmMedium } />
        </div>
        <div>
          <label htmlFor="link_text">UTM Content:</label>
          <input className="input input-bordered w-full" name="link_text" onChange={ (event) => setUtmContent(event.target.value) } value={ utmContent } />
        </div>
        <div>
          <label htmlFor="link_text">Partner Token:</label>
          <input className="input input-bordered w-full" name="partner_token" onChange={ (event) => setPartnerToken(event.target.value) } value={ partnerToken } />
        </div>
        <div>
          <label htmlFor="link_text">Promo Token:</label>
          <input className="input input-bordered w-full" name="promo_token" onChange={ (event) => setPromoToken(event.target.value) } value={ promoToken } />
        </div>
        <div>
          <label htmlFor="link_text">Demand Gen Token:</label>
          <input className="input input-bordered w-full" name="demand_gen" onChange={ (event) => setDemandGen(event.target.value) } value={ demandGen } />
        </div>
        <div className='form-group my-4 text-right'>
          <button className="btn btn-outline rounded mr-2" onClick={ props.onClose }>Cancel</button>
          <button className='btn btn-primary rounded' onClick={ handleSaveLink }>Save</button>
        </div>
      </div>
    );
  }
};

export default PromotionLinkForm;
