import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
// import { useOutletContext } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import UIContextIndicator from '../../shared/UIContextIndicator';
import UploadService from '../../../service/UploadService';


const UploadStepOne = (props) => {

  // const [authContext, setAuthContext] = useOutletContext();
  const { authState } = useOktaAuth();
  const [isUploading, setIsUploading] = useState(false);
  const [listFile, setListFile] = useState(null);

  const onDrop = (async (acceptedFiles) => {
    setIsUploading(true);
    if (Array.isArray(acceptedFiles)) {
      setListFile(acceptedFiles[0]);
    } else {
      setListFile(acceptedFiles);
    }

    const resp = await UploadService.uploadFile(authState.accessToken, acceptedFiles);

    setIsUploading(false);
    console.debug('RESPONSE', resp);
    return props.onComplete(resp);
  });

  const {getRootProps, getInputProps} = useDropzone({ 
    accept: "text/csv",
    onDrop
  });

  const selectedFileMessage = () => {
    return listFile == null ? 'Click to select file or Drag and drop them here' : listFile.name
  }

  if (isUploading) {
    return <UIContextIndicator message="Uploading..." />
  } else {
    return (
      <div className="space-y-4">
        <h3 className="text-3xl">Step One: - Upload File</h3>

        <form id="upload-form" className="dropzone" encType="multipart/form-data">
          <div className="form-control">
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="w-3/4 border border-slate-600 rounded rounded-xl h-36 text-center align-middle">
                  { selectedFileMessage() }
                </p>
              </div>
            </section>
          </div>
        </form>

        {/* <div>
          <h3>Tips for optimizing your source file:</h3>
          <ol className="list bullet">
            <li>Add a blank column to the start of your source file with a header of <b>_BLANK_</b></li>
            <li>Remove columns that will not be imported</li>
          </ol>
        </div> */}
      </div>
    )
  }
};

UploadStepOne.propTypes = {
  onComplete: PropTypes.func.isRequired
};

UploadStepOne.propTypes = {
  onComplete: () => {}
};

export default UploadStepOne;
