import { useEffect, useState, useHistory } from 'react';
import { dateFormat } from '../../service/UtilityFunctions';


const CampaignListTable = (props) => {

  const [campaignList, setCampaignList] = useState(props.campaigns);

  useEffect(() => {
    if (campaignList != null)  {
      setCampaignList(props.campaigns);
    }
  }, [props.campaigns])

  const handleRowClick = (id=null) => {
    console.debug('handleRowClick: ', id);
  }

  const handleCreateCampaignClick = () => {

    console.debug('HANDLE_CREATE_CLICK: ', props);

    if (props.createHandler) {
      props.createHandler();
    }
  }

  const campaignRows = campaignList.map((item) => {
    return (
      <tr onClick={ (item) => { handleRowClick(item.id) } } key={ item.id }>
        <td><a href={ `/campaigns/${item.id}` }>{ item.title }</a></td>
        <td>{ item.description }</td>
        <td>{ dateFormat(item.launch_date, 'long') }</td>
        <td>{ dateFormat(item.expire_date) }</td>
      </tr>
    )
  });

  return (
    <div className='mx-4'>

      <div className='grid grid-cols-2 p-4'>
        <div><h1 className="text-2xl p-0">Marketing Campaigns</h1></div>
        <div className='text-right'>
          <button className='btn-sm btn-primary rounded' onClick={ handleCreateCampaignClick }>Create Campaign</button>
        </div>
      </div>
      
      
      <table className="table-auto w-full">
        <thead>
          <tr className='bg-slate-800 text-white mt-2'>
            <th>Campaign Name</th>
            <th>Campaign Description</th>
            <th>Launch Date</th>
            <th>Expire Date</th>
          </tr>
        </thead>
        <tbody>
          { campaignRows }
        </tbody>

      </table>
    </div>
  )
}

export default CampaignListTable;
