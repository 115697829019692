export const GraphRawProspectCustomerRatioConfig = (graphData) => {
  return {
    credits: {
      enabled: false
    },
    chart: {
      type: 'column'
    },
    title: {
      text: 'Raw Records Prospect / Customer Ratio'
    },
    xAxis: {
      categories: graphData.brands
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total Percentage'
      }
    },
    tooltip: {
      pointFormatter: function() {
        const val = new Intl.NumberFormat('en-US').format(this.y);
        return `${this.series.name} <b>${this.percentage.toFixed(2)}%</b> : (${val})<br />`;
      },
      shared: true
    },
    plotOptions: {
      column: {
        stacking: 'percent'
      }
    },
    series: [
      {
        name: 'Prospects',
        data: graphData.prospects
      },
      {
        name: 'Customers',
        data: graphData.customers
      }
    ]
  };
}
