import axios from 'axios';
import {
  API_ENDPOINT,
  API_REQUEST_HEADERS
} from './ServiceHelpers';

const UploadService = {
  
  async uploadFile(jwt, fileData) {

    const contentTypeHeader = {
      "Content-Type": "multipart/form-data; boundry="+ Math.random().toString().substring(2)
    };
    const uploadHeaders = Object.assign(API_REQUEST_HEADERS(jwt), contentTypeHeader);
    const uploadData = new FormData();
    uploadData.append("user_file", fileData[0]);
    return await axios({
      ...uploadHeaders,
      method: 'PUT',
      url: `${API_ENDPOINT}/list_management_tasks/upload`,
      data: uploadData,
      onUploadProgress: (progressEvent) => {
        console.debug('UPLOAD_PROGRESS: ', progressEvent);
        return parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
      }
    })
    .then(async resp => {
      console.debug('maropsUploader.resp: ', resp);
      return resp.data;
    })
    .catch(err => {
      console.debug('maropsUploader.catch: ', err.message);
      return err;
    });
  },

  async createUploadJob(jwt, payload) {
    const postURL = `${API_ENDPOINT}/list_management_tasks`;
    console.debug('POST_URL: ', postURL);
    return await axios({
      ...API_REQUEST_HEADERS(jwt),
      method: 'POST',
      url: postURL,
      data: {
        list_management_task: payload,
      },
    }).then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw(err);
    })
  }
}

export default UploadService;
