
import axios from 'axios';
import {
  API_ENDPOINT, 
  API_REQUEST_HEADERS
} from './ServiceHelpers';

export const fetchDbActivity = async (jwt) => {
  return axios.get(`${API_ENDPOINT}/dashboard/db_activity`, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    })
}

export const fetchDbSources = async (jwt) => {
  return axios.get(`${API_ENDPOINT}/dashboard/capdb_sources`, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    })
}

export const fetchProspectRatio = async (jwt) => {
  return axios.get(`${API_ENDPOINT}/dashboard/customer_ratio`, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    })
}
