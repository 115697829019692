import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import UploadStepOne from '../components/list-management/upload/UploadStepOne';
import UploadStepTwo from '../components/list-management/upload/UploadStepTwo';
import UploadStepThree from '../components/list-management/upload/UploadStepThree';
import MarOpsFlashAlert from '../components/shared/MarOpsFlashAlert';
import UploadService from '../service/UploadService';

const ListUploadPage = () => {

  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [paneTwoVisible, setPaneTwoVisible] = useState(false);
  const [paneThreeVisible, setPaneThreeVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldMappings, setFieldMappings] = useState({});
  const { authState } = useOktaAuth();

  useEffect(() => {
    console.debug('ListUploadPage.useEffect.currentStep: ', currentStep);
  }, [currentStep])

  useEffect(() => {
    if (fieldMappings != null) {
      console.debug('ListUploadPage.useEffect.fieldMapping', fieldMappings);
    }
  }, [fieldMappings])

  const onUploadComplete = (resp) => {
    if (resp.code == 'ERR_BAD_REQUEST') {
      setErrorMessage(resp.response.data.error);
    } else {
      setCurrentStep(2);
      setPaneTwoVisible(true);
      setPaneThreeVisible(false);
      setFileData(resp);
    }
  };

  const onMappingComplete = (resp) => {
    setFieldMappings(resp);
    setCurrentStep(3);
    setPaneTwoVisible(true);
    setPaneThreeVisible(true);
  };

  const onConfirm = (data) => {
    const payload = Object.assign({}, data, fileData);
    return UploadService.createUploadJob(authState.accessToken, payload).then(async (resp) => {
      history.push('/list-management');
    })  
    .catch(err => {
      setErrorMessage(err.message);
    })
  }

  return (
    <div className="w-full">

      <MarOpsFlashAlert variant="error" message={ errorMessage } />

      <h1 className="text-5xl">Upload List</h1>
      <div className="grid grid-cols-3 space-x-6">
        <UploadStepOne onComplete={ onUploadComplete } />
        <UploadStepTwo visible={ paneTwoVisible } fileData={ fileData } onSubmit={ onMappingComplete} />
        <UploadStepThree visible={ paneThreeVisible } mappings={ fieldMappings } onSubmit={ onConfirm } />
      </div>
    </div>
  )
}

export default ListUploadPage;
