import { useState } from 'react';
import { Link } from 'react-router-dom';
import UIContextIndicator from '../components/shared/UIContextIndicator';
import { fetchFilteredJobList, launchScrubTask } from '../service/ListManagementService';
import TableUserLists from '../components/list-management/TableUserLists';
import PanelListJobResult from '../components/list-management/PanelListJobResult';
import { useOktaAuth } from '@okta/okta-react';
// import TabImportList from '../components/list-management/tabImportList';


const ListManagementPage = () => {

  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [jobList, setJobList] = useState(null);
  const [activeJob, setActiveJob] = useState(null);

  if (jobList === null) {
    fetchFilteredJobList(authState.accessToken).then(userJobs => {
      console.debug('userJobs: ', userJobs);
      setJobList(userJobs);
      setIsLoading(false);
    })
    .catch(err => {
      console.debug("EXCEPTION -> fetchFilteredJobList: ", err.message);
    });
  }

  const handleJobSelect = (job) => {
    setActiveJob(job);
  };

  const handleLaunchScrub = async (scrubTask) => {
    console.debug('handleLaunchScrub: ', scrubTask);
    return launchScrubTask(authState.accessToken, scrubTask).then(job => {
        console.debug('API - launchScrubTask.response: ', job);
        // const job = resp.data;
        setActiveJob(job);
        return job;
      })
      .catch(err => {
        console.debug('ERROR: ', err);
      });
  };

  if (isLoading) {
    return (
      <UIContextIndicator message={ "Scrubbing List" } />
    )
  } else {
    return (
      <div className="w-full">
        
        <div className="grid grid-cols-2 m-4 gap-4">
          <h1 className="text-3xl">List Management</h1>
          <div className="text-right"><Link className="btn btn-primary" to="/list-management/upload-list">Upload List</Link></div>
          <TableUserLists
            userJobs={ jobList }
            handleJobSelect={ handleJobSelect }
          />
          <div className="border-left">
            <PanelListJobResult 
              job={ activeJob }
              taskLauncher={ handleLaunchScrub }
            />
          </div>
      </div>
    </div>
    )
  }
}

export default ListManagementPage;
