import { useState } from "react";

const PrintPromoForm = (props) => {
  return (
    <div className="m-4">
      <h1 className="text-3xl">Print Format Promotion</h1>
      <p>Use this promotion type type when creating content or links for a printed media campaign.</p>
      { JSON.stringify(props) }
    </div>
  )
}

export default PrintPromoForm;
