import { useState, useEffect } from "react";


const SocialPromoForm = (props) => {

  const [socialPlatform, setSocialPlatform] = useState(null);
  const [destinationUrl, setDestinationUrl] = useState(null);
  const [budgetCost, setBudgetCost] = useState(null);

  useEffect(() => {

  }, [socialPlatform]);

  const handleSave = (data) => {
    console.debug('SocialMediaPromo.handleSave')
  };

  return (
    <div className="m-4">
      <h1 className="text-3xl">Social Media Promo Form</h1>
      <div>{ JSON.stringify(props) }</div>

      <div className='form-group my-4'>
        <label htmlFor="socialPlatform" className="pr-4 font-semibold">Social Platform: <span className="text-xs font-normal">(Select the platform this promotion is for.)</span></label>
        <select type='text' name='socialPlatform' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setSocialPlatform(event.target.value) }>
          <option value="">-- Platform --</option>
          <option value="fb">Facebook</option>
          <option value="twitter">Twitter</option>
          <option value="li">LinkedIn</option>
        </select>
      </div>

      <div className='form-group my-4'>
        <label htmlFor='destinationUrl' className="pr-4 font-semibold">Base Destination URL: <span className="text-xs font-normal">(Do not include UTMs or other parameters in this link)</span></label>
        <input type='text' name='destinationUrl' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setDestinationUrl(event.target.value) } />
      </div>

      <div className='form-group my-4'>
        <label htmlFor='cost' className="pr-4 font-semibold">Estimated Cost / Budget:</label>
        <input type='text' name='cost' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setBudgetCost(event.target.value) } />
      </div>

      <div className="form-group my-4">
        <button className="btn btn-primary" onClick={ () => handleSave() }>Save</button>
      </div>
    </div>
  )
}

export default SocialPromoForm;
