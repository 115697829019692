import { useState, useEffect } from 'react';
// import { useOutletContext } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import EmailAnalyzerSearchBox from '../components/email-analyzer/EmailAnalyzerSearchBox';
import EmailAnalyzerPanelList from '../components/email-analyzer/EmailAnalyzerPanelList';
import EmailAnalyzerPanelDetail from '../components/email-analyzer/EmailAnalyzerPanelDetail';
import EmailAnalyzerService from '../service/EmailAnalyzerService';
import MarOpsFlashAlert from '../components/shared/MarOpsFlashAlert';
import UIContextIndicator from '../components/shared/UIContextIndicator';

const EmailAnalyzer = (props) => {
    const { authState } = useOktaAuth();
    const [masterEmailList, setMasterEmailList] = useState(null);
    const [filteredResults, setFilteredResults] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [flashContext, setFlashContext] = useState(null);
    const [flashMessage, setFlashMessage] = useState('');
    const [filterDraft, setFilterDraft] = useState(null);
    const [filterTest, setFilterTest] = useState(null);

    useEffect(() => {
      console.debug('AUTH_STATE_AUTHENTICATED_CHANGED: ', authState.isAuthenticated)
    }, [authState.isAuthenticated])

    if (authState.isAuthenticated && masterEmailList === null) {
      EmailAnalyzerService.getMarketoEmailAll(authState.accessToken)
        .then(resp => {
          console.debug(resp);
          setMasterEmailList(resp);
          setIsLoading(false);
          setFilteredResults(resp);
        })
        .catch(err => {
          setIsLoading(false);
        })
    }

    const logout = async () => {
      // await authState.signOut();
      authState.accessToken = ''
      authState.isAuthenticated = false;
    }

    const handleListSelection = (id) => {
      console.debug('Handle List Selection', id);
      if (id != null) {
        EmailAnalyzerService.getMarketoEmailById(authState.accessToken, id).then(email => {
            console.debug('EmailAnalyzer.handleListSelection.getMarketoEmail.response: ', email);
            setSelectedEmail(email);
            setIsLoading(false);
          })
          .catch(err => {
            console.debug('Handle List Selection Error: ', err.message);
            if (err.message.includes('401')) {
              logout();
            }

            setFlashContext('error')
            setFlashMessage(err.message);
            setIsLoading(false);
          });
      }
    }

    const appendItemToList = (item) => {
      const clonedList = masterEmailList;
      clonedList.push(item);
      setMasterEmailList(clonedList);
    }

    const performSearch = async (query) => {
      setIsLoading(true);
      EmailAnalyzerService.searchMarketoEmail(authState.accessToken, query)
        .then(resp => {
          setMasterEmailList(resp);
          setIsLoading(false);
          setFilterDraft('all');
          setFilterTest('all');
        })
        .catch(err => {
          console.debug("API_ERROR_CODE.performSearch: ", err.code);
          if (err.code === 'ERR_BAD_REQUEST') {
            // setAuthContext(false);
          
          } else {
            setFlashContext('error')
            setFlashMessage(err.message);
          }
          setIsLoading(false);
        })
    }

    const performImport = (marketoEmailUrl) => {
      setIsLoading(true);
      EmailAnalyzerService.importMarketoEmail(authState.accessToken, marketoEmailUrl)
        .then(resp => {
          appendItemToList(resp);
          setIsLoading(false);
        })
        .catch(err => {
          console.debug("API_ERROR_CODE.performImport: ", err.code);
          if (err.code === 'ERR_BAD_REQUEST') {
            // setAuthContext(false);
          } else {
            setFlashContext('error')
            setFlashMessage(err.message);
            setIsLoading(false);
          }
        })
    }

    const applyListFilters = (draft, test) => {
      if (masterEmailList !== null) {
        const filteredDraftList = masterEmailList.map(item => {
          if (draft !== 'all') {
            if (item.marketo_status == draft) {
              return item;
            }
          } else {
            return item;
          }
        });

        const filteredResults = filteredDraftList.map(draftItem => {

          if (draftItem != undefined) {
              if (test == 'errors') {
                if (draftItem.scan_test_error > 0) {
                  return draftItem;
                }
              }

              if (test == 'warnings') {
                if (draftItem.scan_test_warn > 0) {
                  return draftItem;
                }
              }

              if (test == 'success') {
                if (draftItem.scan_test_warn == 0 && draftItem.scan_test_error == 0) {
                  return draftItem;
                }
              }

              if (test == 'all') {
                return draftItem;
              }
            }

          });

        setFilteredResults(filteredResults);
      }
    }

    if (isLoading) {
      return <UIContextIndicator message="Scanning..." />
    } else {
      return (
        <div className="w-full">
          <MarOpsFlashAlert variant={ flashContext } message={ flashMessage } />
          <div className="grid grid-cols-2 border-b py-4 bg-slate-300">
            <h2 className="text-4xl pl-4">Email Quality Assurance Test Tool</h2>
            <EmailAnalyzerSearchBox onSearch={ performSearch } onImport={ performImport } />
          </div>

          <div className="grid grid-cols-12 space-x-8">
            <div className="col-span-4 border-r">
              <EmailAnalyzerPanelList
                onSelect={ handleListSelection }
                data={ filteredResults }
                activeSelection={ selectedEmail }
                onFilter={ applyListFilters }
              />
            </div>
            <div className="col-span-8">
              <EmailAnalyzerPanelDetail { ...selectedEmail } />
            </div>
          </div>
        </div>
      )
    }
}

export default EmailAnalyzer;
