import { useState, useEffect } from 'react';
import UIContextIndicator from '../components/shared/UIContextIndicator';
import { fetchCampaignEntries, createCampaign } from '../service/CampaignService';
import { useOktaAuth } from '@okta/okta-react';
import CampaignListTable from '../components/campaigns/campaign-list';
import CampaignFormModal from '../components/campaigns/campaign-form-modal';

const CampaignsPage = (props={}) => {
  
  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [campaignList, setCampaignList] = useState(null);
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [userMessage, setUserMessage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [buttonText, setButtonText] = useState('Create Campaign');

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleSubmit = (data) => {
    console.debug('HANDLE_SUBMIT_WITH_REQUEST', data);
    createCampaign(authState, data)
      .then(campaign => {
        console.debug('RESPONSE FROM CREATE CAMPAIGN: ', campaign);
        handleAppendCampaign(campaign);
      })
      .catch(err => {
        setIsLoading(false);
        setUserMessage(err.message);
      })
  }

  const handleAppendCampaign = (campaign) => {
    console.debug("handleAppendCampaign.campaign: ", campaign);
    getCampaigns();
    setModalIsOpen(false);
  }

  const getCampaigns = () => {
    fetchCampaignEntries(authState).then(entries => {
      console.debug('CampaignsPage.campaignList', entries);
      setCampaignList(entries);
      setIsLoading(false);
    })
    .catch(err => {
      console.debug("ERROR HANDLING CAMPAIGN LIST FETCH", err.message);
      setIsLoading(false);
      setUserMessage(err.message);
    });
  }

  if (campaignList === null) {
    getCampaigns();
  }

  if (isLoading) {
    return (
      <UIContextIndicator message={ "Loading Campaigns" } />
    )
  } else {
    return (
      <div>
        <CampaignFormModal 
          isOpen={ modalIsOpen }
          onClose={ closeModal }
          onSubmit={ handleSubmit }
        />
        <CampaignListTable 
          campaigns={ campaignList }
          createHandler={ openModal }
        />
      </div>
    );
  }
}

export default CampaignsPage;
