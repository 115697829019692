import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import UIContextIndicator from './UIContextIndicator';

const DashboardGraph = (props) => {

  if (props.data === null) {
    const message = props.error ? props.error : 'Loading Graph Data...'
    return <UIContextIndicator message={ message } />
  } else {
    return (
      <HighchartsReact
        highcharts={ Highcharts }
        options={ props.data }
      />
    )
  }
}

export default DashboardGraph;
