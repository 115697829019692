import { useState } from "react";

const EmailPromotionForm = (props) => {
  return (
    <div className="m-4">
      <h1 className="text-3xl">Email Promotion</h1>
      { JSON.stringify(props) }
    </div>
  )
}

export default EmailPromotionForm;
