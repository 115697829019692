import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const CampaignFormModal = (props) => {

  const { authStore } = useOktaAuth();
  const [partnerId, setPartnerId] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [launchDate, setLaunchDate] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [assignedUtm, setAssignedUtm] = useState(null);

  const handleSave = () => {

    console.debug('handleSave: ', props);

    const requestData = {
      partner_id: partnerId,
      title: title,
      description: description,
      launch_date: launchDate,
      expire_date: expireDate
    }

    props.onSubmit(requestData);

    // console.debug('Sending Data Payload to Save: ', requestData);

  }

  const isOpenCss = props.isOpen === true ? 'modal-open' : '';

  return (
    <div className={ `modal ${isOpenCss}` } id="validation-rule-form text-sm">
    <div className="modal-box">
      <h3 className="font-semibold text-xl">Create a Campaign</h3>
      <div className="p-4">
        <div className='form-group my-4'>
          <label htmlFor='partnerId'>Brand</label>
          <select name='partner_id' onChange={ (event) => setPartnerId(event.target.value) } className='select w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary'>
            <option value="1">LawPay</option>
            <option value="2">MyCase</option>
            <option value="3">CPA Charge</option>
            <option value="4">AffiniPay</option>
            <option value="5">CasePeer</option>
            <option value="6">Docketwise</option>
            <option value="7">ClientPay</option>
          </select>
        </div>

        <div className='form-group my-4'>
          <label htmlFor='title' className="pr-4 font-semibold">Campaign Name</label>
          <input type='text' name='title' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setTitle(event.target.value) } />
        </div>

        <div className='form-group my-4 grid grid-cols-2 gap-4'>
          <div>
            <label htmlFor='description'>Launch Date</label>
            <input type='date' name='launch_date'  className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setLaunchDate(event.target.value) } />
          </div>
          <div>
          <label htmlFor='description'>Expires Date</label>
          <input type='date' name='expire_date'  className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setExpireDate(event.target.value) } />
          </div>
        </div>
    
        <div className='form-group my-4'>
          <label htmlFor='description' >Campaign Description</label>
          <textarea name='description' className='input w-full h-42 border-base-300 rounded-lg focus:outline-none focus:border-secondary min-height-150' onChange={ (event) => setDescription(event.target.value) } />
        </div>

        <div className='form-group my-4 text-right'>
          <button className="btn btn-outline rounded mr-2" onClick={ props.onClose }>Cancel</button>
          <button className='btn btn-primary rounded' onClick={ handleSave }>Save</button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default CampaignFormModal;
