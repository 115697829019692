import {
  XCircleIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  MinusIcon
} from '@heroicons/react/solid';

const iconSize = 18;
const successColor = '#008C23';
const warningColor = '#A87932';
const errorColor = '#CC0000';
const skippedColor = '#DDDDDD';
const infoColor = '#3ABFF8';

const errorContext = (test) => {
  const sev = test.test_severity.toLowerCase();
  const iconScale = parseInt(test.size) || iconSize; 

  switch(sev) {
    case 'error':
      return (
        <div className="clear-both">
          <div className="h-4 w-4 float-left">
            <XCircleIcon fill={ errorColor } width={ iconScale } /> 
          </div>
          <span className="pl-2 text-sm">{ test.test_name }</span>
        </div>
      );
    case 'warning':
      return (
        <div className="clear-both">
          <div className="h-4 w-4 float-left">
            <ExclamationIcon fill={ warningColor } width={ iconScale } />
          </div>
          <span className="pl-2 text-sm">{ test.test_name }</span>
        </div>
      );
    case 'info':
      return (
        <div className="clear-both">
          <div className="h-6 w-6 float-left">
            <InformationCircleIcon fill={ infoColor } width={ iconScale } />
          </div>
          <span className="pl-2 text-sm">{ test.test_name }</span>
        </div>
      );
    case 'skipped':
      return (
        <div className="clear-both">
          <div className="h-4 w-4 float-left">
            <MinusIcon fill={ skippedColor } width={ iconScale } />
          </div>
          <span className="pl-2 text-sm">{ test.test_name }</span>
        </div>
      );
    default:
      return '';
  }
};


const TestResultWithIcon = (test) => {
  if (test.test_result) {
    const iconScale = parseInt(test.size) || iconSize; 
    return (
      <div className="clear-both">
        <div className="h-4 w-4 float-left">
          <CheckCircleIcon fill={ successColor } width={ iconScale } />
        </div>
        <span className="pl-2 text-sm">{ test.test_name }</span>
      </div>
    );
  } else {
    const contextualError = errorContext(test);
    return contextualError;
  }
};

export default TestResultWithIcon;
