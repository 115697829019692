import TestResultWithIcon from '../../shared/TestResultWithIcon';

const LinkAnalysisRow = (l, i) => {
  const securityTests = l.security;
  const httpExistTest = l.http_status;
  const httpRedirectTests = l.no_redirect;
  const utmCampaignTests = l.utm_campaign;
  const utmSourceTests = l.utm_source;
  const utmMediumTests = l.utm_medium;

  return (
    <div className="py-4" key={ `link-ref-${i}` }>
      <div className="pb-4 text-xs">
        { securityTests.test_url }
      </div>
      <div className="grid grid-cols-3 text-sm">
        <div>
          { TestResultWithIcon(securityTests) }
          { TestResultWithIcon(httpExistTest) }
          { TestResultWithIcon(httpRedirectTests) }
        </div>
        <div>
          { TestResultWithIcon(utmCampaignTests.pass_exists) }
          { TestResultWithIcon(utmCampaignTests.pass_value) }
          { TestResultWithIcon(utmSourceTests.pass_exists) }
          { TestResultWithIcon(utmSourceTests.pass_value) }
        </div>
        <div>
          { TestResultWithIcon(utmMediumTests.pass_exists) }
          { TestResultWithIcon(utmMediumTests.pass_value) }
        </div>
      </div>
    </div>
  );
}

// const CardTextLinks = (textLinks) => {
//   if (textLinks && Object.entries(textLinks.data).length === 0) {
//     return (
//       <Row>
//         <Col sm={ 12 }>No Links Found</Col>
//       </Row>
//     )
//   } 

//   if (textLinks && Object.entries(textLinks.data).length > 0) {
    
//     let linkData = null;
    
//     if (textLinks.data.hasOwnProperty('links')) {
//       linkData = textLinks.data.links;
//     } else {
//       linkData = textLinks.data;
//     }

//     const textResults = linkData.map((l, i) => {
//       return LinkAnalysisRow(l,i);
//     });

//     return (
//       <Card body>
//         <h3>Text Links Analyzed</h3>
//         { textResults }
//       </Card>
//     );

//   }
// }

const CardHtmlLinks = ({ links }) => {

  if (links && Object.entries(links).length > 0) {

    const linkResults = links.map((l, i) => {
      return LinkAnalysisRow(l,i);
    });
    return (
      <div className="w-full">
        <h2 className="text-3xl">Links Analyzed</h2>
        <div className="divide-y divide-solid max-w-screen">
          { linkResults }
        </div>
      </div>
    );
  } else {
    return (
      <p>No Links Found.</p>
    )
  }
}

const CardLinkSummary = (props) => {
  return (
    <div>
      <CardHtmlLinks { ...props.html } />
      {/* <CardTextLinks data={ data.text } /> */}
    </div>
  );
}

export default CardLinkSummary;
