import { useEffect, useState } from 'react';
import { dateFormat } from '../../service/UtilityFunctions';

const PromotionDocumentList = (props) => {

  const [linkRows, setLinkRows] = useState([])

  useEffect(() => {
    if (props.docs != null) {
      console.debug('DOCUMENTS LIST: ', props.docs);
      const rows = props.docs.map(item => {
        return (
          <tr key={ item.id }>
            <td>{ item.title }</td>
            <td>{ item.description }</td>
            <td>{ item.download_url }</td>
            <td>{ item.public_url }</td>
            <td>{ dateFormat(item.created) }</td>
            <td>{ dateFormat(item.updated_at) }</td>
            <td><button className="btn btn-xs btn-primary">View</button></td>
          </tr>
        )
      });
      setLinkRows(rows);
    }
  }, [props.docs]);

  const initAddDocument = () => {
    console.debug('Open modal to add document.')
  }

  return (
    <div className="my-4">
      <div className="grid grid-cols-2 bg-slate-800 text-white px-2 p-1">
        <div><h1 className="text-xl py-2">Promotion Documents</h1></div>
        <div className="text-right py-2 pr-2"><button className="btn btn-sm btn-primary" onClick={ initAddDocument }>Add Document</button></div>
      </div>
      <div>
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-slate-500 text-white p-2">
              <td className="p-2">Document Title</td>
              <td>Description</td>
              <td>Download URL</td>
              <td>Public URL</td>
              <td>Date Created</td>
              <td>Last Updated</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            { linkRows }
          </tbody>
        </table>
      </div>
    </div>
  )

}

PromotionDocumentList.PropTypes = {
  docs: {
    type: Array,
    required: true
  },
  onAdd: {
    type: Function,
    required: true
  },
  onEdit: {
    type: Function,
    required: true
  },
  onSave: {
    type: Function,
    required: true
  }
};

PromotionDocumentList.defaultProps = {
  docs: [],
  onAdd: () => {},
  onEdit: () => {},
  onSave: () => {}
}

export default PromotionDocumentList;
