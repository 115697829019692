import { useEffect, useState } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { useParams } from 'react-router-dom';
import { fetchPromotion } from "../service/PromotionService";

import PromotionDocumentList from "../components/promotions/promotion-document-list";
import PromotionImageList from "../components/promotions/promotion-image-list";
import PromotionUrlList from "../components/promotions/promotion-url-list";
import DynamicModal from "../components/shared/DynamicModal";


import { createUrlLink, updateUrlLink } from "../service/UrlLinkService";

const initialPromoState = {
    id: '',
    title: '',
    description: '',
    includes_gift: false,
    documents: [],
    images: [],
    links: [],
};

const PromotionDetailPage = () => {

  const [promo, setPromoData] = useState(initialPromoState);
  const [docList, setDocList] = useState(initialPromoState.documents);
  const [imgList, setImgList] = useState(initialPromoState.images);
  const [urlList, setUrlList] = useState(initialPromoState.links);

  const [modalComponent, setModalComponent] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const params = useParams();
  const { authState } = useOktaAuth();

  useEffect(() => {
      fetchPromotion(authState, params.id)
        .then(resp => {
          console.debug('RESPONSE_FROM_PROMOTION: ', resp);
          setPromoData(resp.promo);
          setDocList(resp.documents);
          setImgList(resp.images);
          setUrlList(resp.links);
        })
        .catch(err => {
          console.error('Unable to fetch promotion', err);
        })
  }, []);

  const handleDocumentAppend = (document) => {
    console.debug('Append Document to List', document);
  };

  const handleImageAppend = (image) => {
    console.debug('Append Image Callback', image);
  };

  const handleLinkAppend = (link) => {
    console.debug('Append Link Callback', link);
  };

  const updateModalState = (component, data) => {
    setModalComponent(component);
    setModalData(data);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const modalSaveCallback = (component, data) => {
    console.debug('modalSaveCallback.component: ', component);
    console.debug('modalSaveCallback.data: ', data);

    updateUrlLink(authState, data)
      .then(resp => {
        console.debug('API_RESPONSE: ', resp);
      })
      .catch(err => {
        console.error('API_EXCEPTION: ', err.message);
        const error = new Error('Error Saving URL Link: ', { cause: { detail: err }} )
        return error.message;
      })
      .finally(() => {
        setModalIsOpen(false);
      })
  };

  return (
    <div className="m-8">
      <DynamicModal 
        isOpen={ modalIsOpen }
        component={ modalComponent }
        data={ modalData }
        onSave={ modalSaveCallback } 
        onClose={ closeModal }  
      />

      <h1 className="text-3xl">Promotion Detail Page</h1>
      <p>{ promo.title }</p>
      <p>{ promo.description }</p>
      <p>{ promo.launchDate }</p>
      <p>{ JSON.stringify(promo) }</p>

      <PromotionDocumentList
        docs={ docList } 
        onCreate={ (component, doc) => handleDocumentAppend(component, doc) } 
        onSave={ (component, doc) => updateModalState(component, doc) }
        onAdd={ (component, doc) => updateModalState(component, doc) } 
        onEdit={ (component, doc) => updateModalState(component, doc) }
      />

      <PromotionImageList 
        images={ imgList }
        onCreate={ (component, img) => handleImageAppend(component, img) }
        onSave={ (component, img) => modalSaveCallback(component, link) }
        onAdd={ (img) => updateModalState('image', img) }
        onEdit={ (img) => updateModalState('image', img) }
      />

      <PromotionUrlList 
        links={ urlList }
        onCreate={ (component, link) => handleLinkAppend(component, link) }
        onSave={ (component, link) => modalSaveCallback(component, link) } 
        onAdd={ (component, link) => updateModalState(component, link) }
        onEdit={ (component, link) => updateModalState(component, link) } 
      />
    </div>
  )
};

export default PromotionDetailPage;
