import { useState, useEffect } from 'react';
import { dateFormat } from '../../service/UtilityFunctions';

const CampaignPromotionsTable = (props) => {

  const [campaignPromotions, setCampaignPromotions] = useState(null);

  useEffect(() => {

    console.debug("USE_EFFECT_PROMOS: ", props.promos);
    if (props.promos != null) {
      console.debug('PASSED_PROMOS: ', props.promos);
      const promoList = props.promos.map(item => {
          return (
            <tr key={ item.id }>
              <td><a href={ `/promotions/${item.id}/` }>{ item.title }</a></td>
              <td>{ item.promo_type_id }</td>
              <td>{ dateFormat(item.launch_date) }</td>
              <td>{ dateFormat(item.expire_date) }</td>
            </tr>
          )
        });
      setCampaignPromotions(promoList);
    }
  }, [props.promos]);


  const handleCreateClick = () => {
    props.createHandler();
  }

  return (
    <div className="mt-4">
      <div className="grid grid-cols-2 p-2">
        <div><h2 className="text-2xl">Campaign Promotions:</h2></div>
        <div className='text-right'>
          <button 
            onClick={ handleCreateClick }
            className="btn-sm btn-primary rounded">Create Promotion</button></div>
      </div>
      
      <table className="table-auto w-full">
        <thead>
          <tr className="bg-slate-800 text-white text-center">
            <th>Title</th>
            <th>Type</th>
            <th>Launch Date</th>
            <th>Expire Date</th>
          </tr>
        </thead>
        <tbody>
          { campaignPromotions }
        </tbody>
      </table>
    </div>
  )
};

export default CampaignPromotionsTable;
