import axios from 'axios';
import { API_ENDPOINT, API_REQUEST_HEADERS } from './ServiceHelpers';

export const fetchProxyEntries = (authState) => {
  const url = `${API_ENDPOINT}/form_proxies`;
  return axios.get(url, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw new Error('Failed to retrieve proxy list:', { cause: err });
    });
};

export const fetchOriginalSubmission = (authState, ref) => {
  const url = `${API_ENDPOINT}/form_proxies/reference`;
  return axios.post(url, { reference: ref }, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw new Error('Failed to retrieve original request data:', { cause: err });
    });
};
