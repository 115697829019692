import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';

const NavigationBar = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const logout = async () => oktaAuth.signOut();

  const [userName, setUserName] = useState('');
  const [userInitials, setUserInitials] = useState('');

  useEffect(() => {
    if(!authState.isAuthenticated) { 
      oktaAuth.signInWithRedirect();
    } else {
      const u = authState.idToken.claims.name;
      setUserName(u);
      const initials = initalsFromName(u);
      setUserInitials(initials);
    }
  }, [authState]);

  const initalsFromName = (n) => {

    let initials = '';
    n.split(' ').forEach(part => { 
      initials += part[0] 
    });
    return initials;
  }

  return (
    <div className="navbar bg-base-content">
      <div className="flex-1">
        <Link to="/dashboard" className="btn normal-case text-lg">Dashboard</Link>
        <Link to="/validation-rules" className="btn normal-case text-lg">Validation Rules</Link>
        <Link to="/email-analyzer" className="btn normal-case text-lg">Email Analyzer</Link>
        <Link to="/partner-verification" className="btn normal-case text-lg">Partner Verification</Link>
        <Link to="/list-management" className="btn normal-case text-lg">List Uploads</Link>
        <Link to="/form-proxy" className="btn normal-case text-lg">Form Proxy</Link>
        <Link to="/enrichment" className="btn normal-case text-lg">Enrichment</Link>
        {/* <Link to="/campaigns" className="btn normal-case text-lg">Campaigns</Link> */}
      </div>
      <div className="flex-none gap-2">
        <span className="text-base-100">{ userName }</span>
        <div className="dropdown dropdown-end">
          <div tabIndex="0" className="btn btn-circle avatar placeholder bg-slate-500">
            <div className="bg-slate-300 text-slate-800 rounded-full w-24">
              <span className="text-xl">{ userInitials }</span>
            </div>
          </div> 
            <ul className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
              <li><Link to="/settings">Settings</Link></li>
              <li><Link to="/" onClick={ logout }>Logout</Link></li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default NavigationBar;
