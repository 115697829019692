import PropTypes from 'prop-types';
import DashboardCard from '../shared/DashboardCard';
import { SanKeyListScrubResult } from './SanKeyListScrubResult';
import SanKeyGraph from '../shared/SanKeyGraph';

const PanelListJobResult = (props) => {


  const job = props.job || null;

  if (job === null) {
    return (
      <div className="m-4">Please select job from list</div>
    )
  } else {

    const sanKeyData = SanKeyListScrubResult(job);

    const StartScrubLink = () => {
      if (job.task_status == 'COMPLETE') {
        return (
          <button className="m-2 btn btn-sm btn-primary" onClick={ () => props.taskLauncher(job) }>
            Launch Scrub Task
          </button>
        );
      }
      else {
        return;
      }
    }

    const AsanaTaskLink = () => {
      if (job.asana_task_url) {
        return (
          <a className="m-2 btn btn-sm btn-primary" href={ job.asana_task_url } target="_blank" rel="noreferrer">
            View Asana Task
          </a>
        );
      } else {
        return;
      }
    }

    const WrikeTaskLink = () => {
      if (job.wrike_task_url) {
        return (
          <a className="m-2 btn btn-sm btn-primary" href={ job.wrike_task_url } target="_blank" rel="noreferrer">View Wrike Task</a>
        );
      } else {
        return;
      }
    };

    

    return (
      <div>
        <div className="grid">

          <div className="grid grid-cols-2">
            <div className="text-2xl pb-2">Job: { job.task_name }</div>
            <div className="text-2xl pb-2 text-right">{ job.task_status }</div>
          </div>
          
          <div className="text-2xl pb-2">
            Processing Time: { job.execution_time }<br />
            {/* <span className="text-xs pb-2">{ dateFormat(job.task_date_started, 'long', 'long') } - { dateFormat(job.task_date_stopped, 'long', 'long') }</span> */}
          </div> 

          <div className="text-xl pb-2">Type: { job.task_type }</div>

          <div className="grid-flex gap-4">
            <StartScrubLink />
            <AsanaTaskLink />
            <WrikeTaskLink />
          </div>

          <hr className="my-4" />

        </div>

        <div className="grid sm:grid-cols-3 grid-cols-7 gap-2">
          <DashboardCard title="Total Processed" value={ parseInt(job.records_processed) } />
          <DashboardCard title="Net New Leads" value={ parseInt(job.net_new_passed) + parseInt(job.net_new_failed) } />
          <DashboardCard title="Known Leads" value={ parseInt(job.known_passed) + parseInt(job.known_failed) } />
          <DashboardCard title="Passed Acceptance" value={ parseInt(job.net_new_passed) + parseInt(job.known_passed) } />
          <DashboardCard title="Failed Acceptance" value={ parseInt(job.net_new_failed) + parseInt(job.known_failed) } />
          <DashboardCard title="Errors" value={ parseInt(job.records_error) } />
        </div>

        <SanKeyGraph data={ sanKeyData } error="" />

        <div className="text-xl pb-2">Original File: 
          <div className="text-xs p-2 bg-slate-200 rounded border border-slate-800">{ job.list_source_file }</div>
        </div>

        <div className="text-xl pb-2">Output File:
          <div className="text-xs p-2 bg-slate-200 rounded border border-slate-800">{ job.list_result_file }</div>
        </div>

        <div className="text-xl pb-2">Scrubbed File:
          <div className="text-xs p-2 bg-slate-200 rounded border border-slate-800">{ job.list_scrub_file }</div>
        </div>


      </div>
    )
  }

}

PanelListJobResult.propTypes = {
  job: PropTypes.object,
  taskLauncher: PropTypes.func.isRequired,
}

PanelListJobResult.defaultProps = {
  job: null,
  taskLauncher: () => {},
}

export default PanelListJobResult;
