
import axios from 'axios';

const devEndpoint = process.env.REACT_APP_MAROPS_LOCAL === 'true' ? 'http://localhost:8888' : 'https://qa-api.marops.affinipay.com'
const prodStaging = window && window.location.href.includes('https://marops.affinipay.com') ? 'https://api.marops.affinipay.com' : devEndpoint
export const API_ENDPOINT = process.env.NODE_ENV === 'production' ? prodStaging : devEndpoint;

export const API_REQUEST_HEADERS = (jwt) => {
  return {
    headers: { 'Authorization': `Bearer ${jwt.accessToken}` } 
  };
}

export const handleCredentialResponse = async function(response, cb) {
  await axios.post(`${API_ENDPOINT}/auth/validate_claims`, { id_token: response.credential })
    .then(resp => {
      cb(resp.data);
    })
    .catch(err => {
      throw err;
    })
}
