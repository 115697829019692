import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const UploadStepThree = (props) => {

  const [attributeMap, setAttributeMap] = useState(props.mappings);
  const [taskName, setTaskName] = useState('');
  const [asanaTaskUrl, setAsanaTaskUrl] = useState('');
  const [checkedLawpay, setCheckedLawpay] = useState(false);
  const [checkedMyCase, setCheckedMyCase] = useState(false);
  const [checkedCpaCharge, setCheckedCpaCharge] = useState(false);
  const [checkedClientPay, setCheckedClientPay] = useState(false);
  const [checkedAffiniPay, setCheckedAffiniPay] = useState(false);
  const [checkedCasePeer, setCheckedCasePeer] = useState(false);
  const [checkedDocketwise, setCheckedDocketwise] = useState(false);

  useEffect(() => {
    if (props.mappings != {}) {
      const newMap = props.mappings;
      setAttributeMap(newMap);
    }
  }, [props.mappings]);

  if (props.visible == true && props.mappings) {
    const mappingConfig = Object.entries(attributeMap).map((item, idx) => {
      const [key,val] = item;
      return (
        <div className="grid grid-cols-2 my-4" key={ idx }>
          <div>{ key }</div>
          <div>{ val }</div>
        </div>
      );
    });

    const handleSubmit = () => {

      const activeBrands = [];
      if (checkedLawpay)      { activeBrands.push('lawpay') }
      if (checkedMyCase)      { activeBrands.push('mycase') }
      if (checkedAffiniPay)   { activeBrands.push('affinipay') }
      if (checkedCpaCharge)   { activeBrands.push('cpacharge') }
      if (checkedClientPay)   { activeBrands.push('clientpay') }
      if (checkedCasePeer)    { activeBrands.push('casepeer') }
      if (checkedDocketwise)  { activeBrands.push('docketwise') }

      const submitPayload = {
        task_name: taskName,
        task_brand: activeBrands.join('|'),
        mapping_config: JSON.stringify(attributeMap),
        asana_task_url: asanaTaskUrl,
      };
      props.onSubmit(submitPayload);
    }

    return (
      <div>
        <h1 className="text-3xl">Step Three: Confirm Mappings</h1>
        
        { mappingConfig }

        <div className="text-l mt-8">
          <label>List Name</label>
          <div className="form-control">
            <input className="input input-bordered rounded focus:border-none" onChange={ (event) => setTaskName(event.target.value) } value={ taskName } />
          </div>
        </div>

        <div className="text-l mt-8">
          <label>Asana Task Link <span className="text-xs">(Required to attach the results file on the Asana Task)</span>
          </label>
          <div className="form-control">
            <input className="input input-bordered rounded focus:border-none" onChange={ (event) => setAsanaTaskUrl(event.target.value) } value={ asanaTaskUrl } />
          </div>
        </div>

        <div className="text-l mt-8">
          <label>Customers &amp; Unsubscribes for Brands</label>

          <div className="form-group">
            <input id="brand-lawpay" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedLawpay(event.target.checked) } value="lawpay" checked={ checkedLawpay } />
            <label htmlFor="brand-lawpay" className="pl-4">LawPay</label>
          </div>
          <div className="form-group">
            <input id="brand-cpacharge" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedCpaCharge(event.target.checked) } value="cpacharge" checked={ checkedCpaCharge } />
            <label htmlFor="brand-cpacharge" className="pl-4">CPACharge</label>
          </div>
          <div className="form-group">
            <input id="brand-clientpay" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedClientPay(event.target.checked) } value="clientpay" checked={ checkedClientPay } />
            <label htmlFor="brand-clientpay" className="pl-4">ClientPay</label>
          </div>
          <div className="form-group">
            <input id="brand-affinipay" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedAffiniPay(event.target.checked) } value="affinipay" checked={ checkedAffiniPay } />
            <label htmlFor="brand-affinipay" className="pl-4">AffiniPay</label>
          </div>
          <div className="form-group">
            <input id="brand-mycase" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedMyCase(event.target.checked) } value="mycase" checked={ checkedMyCase } />
            <label htmlFor="brand-mycase" className="pl-4">MyCase</label>
          </div>
          <div className="form-group">
            <input id="brand-casepeer" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedCasePeer(event.target.checked) } value="casepeer" checked={ checkedCasePeer } />
            <label htmlFor="brand-casepeer" className="pl-4">CASEPeer</label>
          </div>
          <div className="form-group">
            <input id="brand-docketwise" type="checkbox" className="checkbox checkbox-md" onChange={ (event) => setCheckedDocketwise(event.target.checked) } value="docketwise" checked={ checkedDocketwise } />
            <label htmlFor="brand-docketwise" className="pl-4">Docketwise</label>
          </div>

        </div>

        <div className="mt-8">
          <button className="btn btn-primary" onClick={ () => handleSubmit() }>Process List</button>
        </div>
      </div>
    )
  }

}

UploadStepThree.propTypes = {
  visible: PropTypes.bool.isRequired,
  mappings: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

UploadStepThree.defaultProps = {
  visible: false,
  mappings: {},
  onSubmit: () => {},
};

export default UploadStepThree;
