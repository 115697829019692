import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react'
import DashboardGraph from '../components/shared/DashboardGraph';
import { GraphConfigDatabaseActivity } from '../components/dashboard/GraphConfigDatabaseActivity';
import { GraphDataSourceConfig } from '../components/dashboard/GraphDataSourceConfig';
import { GraphRawProspectCustomerRatioConfig } from '../components/dashboard/GraphRawProspectCustomerRatioConfig';
import { fetchDbActivity, fetchDbSources, fetchProspectRatio } from '../service/DashboardDataService';

const Dashboard = (props) => {

  const [dbActivityData, setDbActivityData] = useState(null);
  const [dbSourceData, setDbSourceData] = useState(null);
  const [dbProspectRatio, setDbProspectRatio] = useState(null);
  const [dbActivityLoading, setDbActivityLoading] = useState(false);
  const [dbSourceLoading, setDbSourceLoading] = useState(false);
  const [dbRatioLoading, setDbRatioLoading] = useState(false);
  const [dbActivityError, setDbActivityError] = useState(null);
  const [dbSourceDataError, setDbSourceDataError] = useState(null);
  const [dbRatioError, setDbRatioError] = useState(null);
  const { authState } = useOktaAuth();

  if (authState.isAuthenticated === true && dbActivityData === null && dbActivityLoading === false) {
    setDbActivityLoading(true);
    fetchDbActivity(authState.accessToken).then(resp => {
      const dataActivity = GraphConfigDatabaseActivity(resp);
      setDbActivityData(dataActivity);
    })
    .catch(err => {
      console.error('ERROR:fetchDbActivity - ', err);
      const errObj = err.response.data;
      setDbActivityError(errObj.error);
    });
  }

  if (authState.isAuthenticated === true && dbSourceData === null && dbSourceLoading === false) {
    setDbSourceLoading(true);
    fetchDbSources(authState.accessToken).then(resp => {
      const prospectDataSources = GraphDataSourceConfig(resp);
      setDbSourceData(prospectDataSources);
    })
    .catch(err => {
      const errObj = err.response.data;
      setDbSourceDataError(errObj.error);
    });
  }

  if (authState.isAuthenticated === true && dbProspectRatio === null && dbRatioLoading === false) {
    setDbRatioLoading(true);
    fetchProspectRatio(authState.accessToken).then(resp => {
      const prospectRatio = GraphRawProspectCustomerRatioConfig(resp);
      setDbProspectRatio(prospectRatio);
    })
    .catch(err => {
      const errObj = err.response.data;
      setDbRatioError(errObj.error);
    });
  }

  return (
    <div>
      <h2 className="text-4xl p-5">Dashboard</h2>
      <div className="grid">
          <DashboardGraph data={ dbActivityData } error={ dbActivityError } />
      </div>
      <div className="grid grid-cols-2">
        <div><DashboardGraph data={ dbSourceData } error={ dbSourceDataError }/></div>
        <div><DashboardGraph data={ dbProspectRatio } error={ dbRatioError } /></div>
      </div>
    </div>
  )
}

export default Dashboard;
