import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

const WebsitePromoForm = (props) => {

  const [promoType, setPromoType] = useState(4);
  const [promoTitle, setPromoTitle] = useState(null);
  const [promoDescription, setPromoDescription] = useState(null);
  const [destinationUrl, setDestinationUrl] = useState(null);
  const [wrikeTask, setWrikeTask] = useState(null);
  const [costBudget, setCostBudget] = useState(null);
  const [launchDate, setLaunchDate] = useState(null);
  const [expireDate, setExpireDate] = useState(null);

  // const { authState } = useOktaAuth();
  const params = useParams();

  useEffect(() => {
    console.debug('WebsitePromoForm.useEffect -> props.promo_type_id: ', props);
  }, [props.promo_type_id])

  const handleSavePromo = () => {

    const payload = {
      campaign_id: params.id,
      promo_type_id: promoType,
      title: promoTitle,
      description: promoDescription,
      launch_date: launchDate,
      expire_date: expireDate
    }

    return props.onSave(payload);
  }

  return (
    <div className="m-4">
      <h1 className="text-3xl">Website Promotion Form</h1>

      <div className='form-group my-4'>
        <label htmlFor='targetUrl' className="pr-4 font-semibold">Base Destination URL: <span className="text-xs font-normal">(Do not include UTMs or other parameters in this link)</span></label>
        <input type='text' name='title' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setDestinationUrl(event.target.value) } />
      </div>

      <div className="form-group my-4">
        <label htmlFor="wrikeTask" className="pr-4 font-semibold">Wrike Task</label>
        <input type="text" name="wrike_task"  className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setWrikeTask(event.target.value) } />
      </div>

      <div className='form-group my-4'>
        <label htmlFor='costBudget' className="pr-4 font-semibold">Estimated Cost / Budget:</label>
        <input type='text' name='title' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setCostBudget(event.target.value) } />
      </div>

      <div className="form-group my-4">
        <label htmlFor="popup-banner"></label>
      </div>

      <div className="form-group my-4">
        <button 
          className="btn btn-primary"
          onClick={ handleSavePromo }  
        >Save</button>
      </div>
    </div>
  )
}

export default WebsitePromoForm;
