import TestResultWithIcon from '../../shared/TestResultWithIcon'

const CardImageSummary = (props) => {
  const { html } = props;
  const noImage = 'No Image Found';
  if (html) {
    const images = html.images || [];
    let imageResults = null;
    if (images === null || images === undefined) {
      return noImage;
    } else {
      if (images) {
          imageResults = images.map((img, i) => {
          const securityTest = img.security;
          const httpExistTest = img.http_status;
          const httpRedirectTest = img.no_redirect;
          return (
            <div key={ i }>
              <div className="grid grid-cols-2">
                <div>
                  <img className="h-48 w-96 object-scale-down" src={ securityTest.test_url } />
                </div>
                <div className="pt-8 text-sm">
                  { TestResultWithIcon(securityTest) }
                  { TestResultWithIcon(httpExistTest) }
                  { TestResultWithIcon(httpRedirectTest) }
                </div>
              </div>
            </div>
          );
        });
        return (
          <div>
            <h2 className="text-3xl">Images Analyzed</h2>
            <div  className="divide-y divide-dashed">
              { imageResults }
            </div>
          </div>
        )
      } else {
        return noImage;
      }
    }
  } else {
    return noImage;
  }
}

export default CardImageSummary;
