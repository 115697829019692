import { useEffect, useState } from "react";

const ModalFormProxyOriginal = (props) => {

  const isOpenCss = props.isOpen === true ? 'modal-open' : '';
  const [originalIsOpen, setOriginalIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [dataMap, setDataMap] = useState([])

  useEffect(() => {
      if (props.item) {
        console.debug('MODAL_PROPS_ITEM_CHANGED: ', props.item);
        setActiveItem(props.item);
        const de = Object.entries(props.item);
        setDataMap(de);
      }
  }, [props.item]);

  useEffect(() => {
    if (props.isOpen) {
      console.debug('MODAL_IS_OPEN_CHANGED: ', props.isOpen);
      setOriginalIsOpen(props.isOpen);
    } 
  }, [props.isOpen]);

  const handleClose = () => {
    if (props.onClose) {
      setActiveItem({});
      props.onClose();
    } else {
      setActiveItem({});
    }
  }

  const originalDataPoints = dataMap.map(item => {
    const [k,v] = item;
    return (
      <div key={ k } className="grid grid-cols-2 border-slate-300 border-b py-2">
        <div>{ k }</div>
        <div>{ v }</div>
      </div>
    )
  });

  return (
    <dialog className={ `modal ${isOpenCss}` }>
      <div className="modal-box w-11/12 max-w-5xl">
        <h3 className="font-semibold text-xl">{ props.title }</h3>

        <div className="text-sm">{ originalDataPoints }</div>
      
        <div className="modal-action">
          <button className="btn btn-outline" onClick={ handleClose }>Close</button>
        </div>
      </div>
    </dialog>
  );

}

ModalFormProxyOriginal.propType = {
  item: Object,
  isOpen: Boolean
}

ModalFormProxyOriginal.defaultProps = {
  item: {},
  isOpen: false
}

export default ModalFormProxyOriginal;
