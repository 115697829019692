const CardHtmlPreview = (props) => {
  const rawContent = props.content_html || 'No Scan Available';
  const htmlText = encodeURIComponent(rawContent);
  const srcString = `data:text/html;charset=utf-8,${htmlText}`;
  return (
    <div className="w-full border-l border-base-300">
      <iframe className="w-full h-1200" height="1200" src={ srcString } title="HTML Preview" />
    </div>
  )
};

export default CardHtmlPreview;
