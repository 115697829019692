import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import DashboardCard from '../shared/DashboardCard';
import TestResultWithIcon from '../shared/TestResultWithIcon';

const defaultResults = {
  lawpay: { results: []},
  cpacharge: { results: [] }
}

const ScanResultsTable = (props) => {

  const { authState } = useOktaAuth();
  const [activeResults, setActiveResults] = useState([]);
  let tableRows = '';

  const scanResults = props.results || [];

  useEffect(() => {
    setActiveResults(scanResults);
  }, [scanResults.length]);

  tableRows = activeResults.map((item, index) => {

    const websitePage = item.partner_slug || '';
    const contentModel = item.contentful_type;
    const partnerToken = item.partner_token || '<MISSING>';
    const partnerPlan = item.partner_plan || '<MISSING>'

    const partnerResult = {
      test_name: partnerToken,
      test_severity: 'error',
      test_result: item.pass_token_test
    };
    const planResult = {
      test_name: partnerPlan,
      test_severity: partnerPlan == '<MISSING>' ? 'error' : 'warning',
      test_result: item.pass_plan_test
    };

    const passFailPartner = <TestResultWithIcon { ...partnerResult } size={ 24 }/>
    const passFailPlan = <TestResultWithIcon { ...planResult } size={ 24 } />

    const domainHost = props.brand == 'associations' ? props.host : props.brand

    return (
      <tr key={ `item-${index}` }>
        <td className="text-sm">{ item.partner_name }</td>
        <td className="text-sm">{ `https://www.${domainHost}.com/partners/${websitePage}` }</td>
        <td className="text-sm">{ item.partner_type }</td>
        <td>{ contentModel }</td>
        <td className="text-xs">{ passFailPartner }</td>
        <td className="text-xs">{ passFailPlan }</td>
      </tr>
    );
  });

  return (
    <div className="overflow-x-auto">
      <div className="grid grid-cols-6 mb-6 space-x-6">
        <DashboardCard title="Partners Processed" value={ props.total_processed } />
        <DashboardCard title="Tokens Passed" value={ props.total_vt_tokens_passed } />
        <DashboardCard title="Tokens Failed" value={ props.total_vt_tokens_failed }/>
        <DashboardCard title="Plans Passed" value={ props.total_vt_plans_passed } />
        <DashboardCard title="Plan Warnings" value={ props.total_vt_plans_warning } />
        <DashboardCard title="Plans Failed" value={ props.total_vt_plans_failed } />
      </div>
      <table className="table w-full text-sm">
        <thead>
          <tr>
            <th>Partner Name</th>
            <th>Website Path</th>
            <th>Partner Type</th>
            <th>Contentful Model</th>
            <th>Partner Token</th>
            <th>Partner Plan</th>
          </tr>
        </thead>
        <tbody>
          { tableRows }
        </tbody>
      </table>
    </div>
  )
};

export default ScanResultsTable;
