import axios from 'axios';
import { API_ENDPOINT, API_REQUEST_HEADERS } from './ServiceHelpers';

const PartnerVerificationService = {
  
  fetchPartnerScanResults: async (jwt) => {
    const url = `${API_ENDPOINT}/partner_verification`;
    return await axios.get(url, API_REQUEST_HEADERS(jwt))
      .then(resp => { 
        return resp.data;
      })
      .catch(err => {
        throw err;
      });
  },

  performPartnerTokenScan: async (jwt, brand) => {
    // console.debug('PARTNER_SCAN_JWT: ', jwt.idToken);
    const url = `${API_ENDPOINT}/partner_verification/perform_scan`;
    return axios.post(url, { brand: brand }, API_REQUEST_HEADERS(jwt))
      .then(resp => {
        return resp.data;
      })
      .catch(err => {
        console.debug("ERROR_PARTNER_VALIDATION: ", err.message);
        throw new Error('Unable to scan partner tokens', { cause: err });
      });
  }
}

export default PartnerVerificationService
