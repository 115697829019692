import axios from 'axios';
import { API_ENDPOINT, API_REQUEST_HEADERS } from './ServiceHelpers';

export const fetchFilteredJobList = async (jwt) => {
  const api_url = `${API_ENDPOINT}/list_management_tasks`;
  return await axios.get(api_url, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
};

export const fetchJobById = (jwt, id) => {
  const api_url = `${API_ENDPOINT}/list_management_tasks/${id}`;
  return axios.get(api_url, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
}

export const createJob = async (jwt, job) => {
  const jobParams = {
    list_management_task: {
      task_name: job.task_name,
      task_type: job.task_type,
      task_filename: job.task_filename,
      asana_task_url: job.asana_task_url
    }
  };
  return axios.post(`${API_ENDPOINT}/list_management_tasks`, jobParams, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
}

export const launchScrubTask = async (jwt, config) => {
  console.debug('STARTING_SCRUB_JOB: ', config);
  return axios.post(`${API_ENDPOINT}/list_management_tasks/scrub`, config, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    })
}
