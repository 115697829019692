import { useState, useEffect } from "react";
import {
  XCircleIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  MinusIcon
} from '@heroicons/react/solid';

const EmailAnalyzerPanelList = ({ onSelect, data, activeSelection, onFilter }) => {
  let emailList = null;

  const [activeEmailId, setActiveEmailId] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [draftFilter, setDraftFilter] = useState('all');
  const [testFilter, setTestFilter] = useState('all');

  useEffect(() => {
    setCurrentItems(data);
  }, [data])


  if (currentItems.length > 0) {
    emailList = currentItems.map(item => {

      if (item) {
        const keyName = `marketoEmail-${item.id}`

        const handleSelection = () => {
          setActiveEmailId(item.id);
          onSelect(item.id);
        }

        const conditionalStyles = activeEmailId === item.id ? ' active-selection' : '';

        const statusIcon = () => {
          if (item.marketo_status == 'approved') {
            return (
              <span className="bg-success success rounded-lg py-0.5 px-2 text-xs">Approved</span>
            );
          } else {
            return (
              <span className="bg-warning text-white rounded-lg py-0.5 px-2 text-xs">Draft</span>
            );
          }
        }

        const testIcon = () => {

          if (item.scan_test_error == 0 && item.scan_test_warn == 0) {
            return <CheckCircleIcon fill="green" width={ 30 } />
          }

          if (item.scan_test_error > 0) {
            return <XCircleIcon fill="red" width={ 30 } />
          }

          if (item.scan_test_warn > 0) {
            return <ExclamationIcon fill="#A87932" width={ 30 } />
          }
        }

        return (
          <div 
            className={ `w-full grid grid-cols-12 gap-2 py-4 px-2 hover:cursor-pointer text-xs hover:bg-blue-200 hover:text-black ${conditionalStyles}` }
            id={ keyName }
            key={ keyName }
            onClick={ handleSelection }
          >
            <div className="col-span-2">{ statusIcon() }</div>
            <div className="col-span-9">{ item.name } </div>
            <div className="col-span-1 text-center">{ testIcon() } </div>
          </div>
        );
      }
    })
  }

  // const handleTestStatusFilter = async (event) => {
  //   setDraftFilter(event.target.value, );
  //   if (event.target.name == 'test_status_filter') {

  //   }

  //   if 
  //   return await onFilter(draftFilter, testFilter);
  // }

  const handleFilterChange = async (event) => {
    
    let draft = draftFilter;
    let test = testFilter;

    if (event.target.name == 'marketo_status_filter') {
      draft = event.target.value;
      await setDraftFilter(draft);
    }

    if (event.target.name == 'test_status_filter') {
      test = event.target.value;
      await setTestFilter(test);
    }
    
    console.debug('FILTER_STATE: ', draftFilter);
    console.debug('FILTER_LOCAL: ', draft);

    return await onFilter(draft, test);
  }

  return (
    <div className="max-h">
      <h2 className="text-3xl">Marketo Emails</h2>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <label htmlFor="marketo_status_filter">Filter Draft Status:</label><br />
          <select className="select w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary" name="marketo_status_filter" onChange={ handleFilterChange }>
            <option value="all">All</option>
            <option value="draft">Draft</option>
            <option value="approved">Approved</option>
          </select>

        </div>
        <div>
          <label htmlFor="test_status_filter">Filter Test Status:</label>
          <select className="select w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary" name="test_status_filter" onChange={ handleFilterChange }>
            <option value="all">All</option>
            <option value="errors">Errors</option>
            <option value="warnings">Warnings</option>
            <option value="success">Success</option>
          </select>

        </div>
      </div>
      <div className="max-h-screen overflow-y-scroll">
        <div className="divide-y divide-dashed divide-slate-800 p-2">
          { emailList }
        </div>
      </div>
    </div>
  );

}

export default EmailAnalyzerPanelList;
