import axios from 'axios';
import { API_ENDPOINT, API_REQUEST_HEADERS } from './ServiceHelpers';

export const getDomainQueue = async (jwt, statusFilter='pending_approval') => {
  console.debug("STATUS_FILTER: ", statusFilter);
  const url = `${API_ENDPOINT}/enrichment_data_verifications/?filter=${statusFilter}`;
  return await axios.get(url, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
};

export const updateEnrichmentResult = async(jwt, item) => {
  console.debug('updateEnrichmentResult.item: ', item);
  const url = `${API_ENDPOINT}/enrichment_data_verifications/${item.id}`;
  return await axios.patch(url, item, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      console.debug('RESPONSE_FROM_UPDATE: ', resp);
      return resp.data;
    })
    .catch(err => {
      console.error('ERROR_RESPONSE: ', err.message);
      throw err;
    });
}
