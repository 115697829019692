import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import NavigationBar from '../components/shared/NavigationBar';

const AuthenticatedLayout = (props) => {

  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if(!authState.isAuthenticated) { 
      oktaAuth.signInWithRedirect();
    }
  }, [authState]);

  return (
    <div className="mx-auto bg-neutral-100">
      
      <NavigationBar />

      <props.component />
    </div>
  )
}

export default AuthenticatedLayout;
