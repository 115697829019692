import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UIContextIndicator from '../components/shared/UIContextIndicator';
import { fetchProxyEntries, fetchOriginalSubmission } from '../service/FormProxyService';
import { useOktaAuth } from '@okta/okta-react';
import SubmissionEntryList from '../components/form-proxy/SubmissionEntryList';
import ModalFormProxyOriginal from '../components/form-proxy/ModalFormProxyOriginal';
import ProxySearchToolBar from '../components/form-proxy/ProxySearchToolBar';
import NotAuthorized from '../components/shared/NotAuthorized';

const FormProxyPage = () => {

  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [proxyList, setProxyList] = useState(null);
  const [activeEntry, setActiveEntry] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleItemSelection = (item) => {
    // console.debug('Handle Item Selection', item);
    setActiveEntry(item);
    setModalTitle('Attribution Profile')
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleSearchRequest = (original) => {

    console.debug('Handle Search Request -> Original Reference: ', original);

    fetchOriginalSubmission(authState, original)
      .then(resp => {
        setActiveEntry(resp);
        setModalTitle('Original Submitted Data Request');
        setModalOpen(true);
      })
      .catch(err => {
        setActiveEntry({});
        setModalOpen(false);
      });
  }

  if (proxyList === null) {
    fetchProxyEntries(authState)
      .then(entries => {
        setProxyList(entries);
        setIsLoading(false);
        setIsAuthorized(true);
      })
      .catch(err => {
        setIsLoading(false);
        handleModalClose();
      });
  }

  if (isLoading) {
    return <UIContextIndicator message={ "Loading Submissions" } />
  } else {

    if (!isAuthorized) {
      return <NotAuthorized />
    } else {
      return (
        <div className="w-full">
          <ModalFormProxyOriginal title={ modalTitle } item={ activeEntry } isOpen={ modalOpen } onClose={ handleModalClose } />
          <div className="grid grid-cols-2">
            <h1 className="text-3xl p-4">Form Proxy Submissions</h1>
            <ProxySearchToolBar onSearchRequest={ (r) => handleSearchRequest(r) } />
          </div>
          <SubmissionEntryList entries={ proxyList } onViewSummary={ handleItemSelection } onViewOriginal={ (r) => handleSearchRequest(r) } />
        </div>
      );
    }
  }
}

export default FormProxyPage;
