import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import UIContextIndicator from './UIContextIndicator';
import SankeyModule from "highcharts/modules/sankey";

SankeyModule(Highcharts);

const SanKeyGraph = (props) => {
  if (props.data === null) {
    const message = props.error ? props.error : 'Loading Graph Data...'
    return <UIContextIndicator message={ message } />
  } else {

    console.debug('SANKEY_PROPS: ', props.data);

    return (
      <HighchartsReact
        highcharts={ Highcharts }
        options={ props.data }
      />
    )
  }
}

export default SanKeyGraph;
