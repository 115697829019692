import { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, useHistory, withRouter } from 'react-router-dom';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import DefaultLayout from './layouts/DefaultLayout';
import Dashboard from './pages/Dashboard';
import ValidationRules from './pages/ValidationRules';
import EmailAnalyzer from './pages/EmailAnalyzer';
import SettingsPage from './pages/SettingsPage';
import FormProxyPage from './pages/FormProxyPage';
import CampaignsPage from './pages/CampaignsPage';
import PartnerVerificationPage from './pages/PartnerVerificationPage';
import ListManagementPage from './pages/ListManagementPage';
import ListUploadPage from './pages/ListUploadPage';
import CampaignDetailPage from './pages/CampaignDetailPage';
import PromotionDetailPage from './pages/PromotionDetailPage';
import DomainEnrichmentPage from './pages/DomainEnrichmentPage';

import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';

import './index.css';

const oktaConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  tokenManager: {
    storage: 'memory'
  }
}

const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {

  const history = useHistory();
  // const loggedIn = useOktaAuth(oktaAuth);

  const restoreOriginalUri = useCallback((_, originalUri = '/') => {
    const token = oktaAuth.authStateManager.accessToken
    if (token != 'undefined' && originalUri == '/') {
      history.push('/dashboard');
    } else {
      history.push(originalUri);
    }
  }, []);

  //  Allows login redirect from Okta Tile
  const authResume = () => {
    history.push('/dashboard');
  }

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri } authResume={ authResume }>
      <Route path="/" exact={ true } component={ DefaultLayout }/>
      <Route path="/login/callback" render={() => <LoginCallback onAuthResume={ authResume } />} />
      <SecureRoute path="/settings" render={() => <AuthenticatedLayout component={ SettingsPage } /> } />
      <SecureRoute path="/dashboard" render={() => <AuthenticatedLayout component={ Dashboard } /> } />
      <SecureRoute path="/validation-rules" render={() => <AuthenticatedLayout component={ ValidationRules } /> } />
      <SecureRoute path="/email-analyzer" render={() => <AuthenticatedLayout component={ EmailAnalyzer } /> } />
      <SecureRoute path="/partner-verification" render={() => <AuthenticatedLayout component={ PartnerVerificationPage } /> } />
      <SecureRoute path="/list-management" exact={ true } render={() => <AuthenticatedLayout component={ ListManagementPage } /> } />
      <SecureRoute path="/list-management/upload-list" render={() => <AuthenticatedLayout component={ ListUploadPage } /> } />
      <SecureRoute path="/form-proxy" render={() => <AuthenticatedLayout component={ FormProxyPage } /> } />
      <SecureRoute path="/enrichment" render={() => <AuthenticatedLayout component={ DomainEnrichmentPage } /> } />
      <SecureRoute path={ `/campaigns/:id` } exact={ true } render={() => <AuthenticatedLayout component={ CampaignDetailPage} />} />
      <SecureRoute path={ `/promotions/:id` } exact={ true } render={() => <AuthenticatedLayout component={ PromotionDetailPage } /> } />
      <SecureRoute path="/campaigns" exact={ true } render={() => <AuthenticatedLayout component={ CampaignsPage } /> } />
      
    </Security>
  );

};

const AppWithRouterAccess = withRouter(App);

const RouterApp = () => {
  return (<Router><AppWithRouterAccess/></Router>);
};

export default RouterApp;
