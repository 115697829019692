import axios from 'axios';
import { API_ENDPOINT, API_REQUEST_HEADERS } from './ServiceHelpers';

export const importMarketoEmail = async (jwt, rawUrl) => {
  const emailPattern = rawUrl.match(/\/[0-9]{4,5}/i) || [''];
  const marketoId = emailPattern && emailPattern[0].replace(/\//g, '');
  const url = `${API_ENDPOINT}/marketo_marketing_emails`

  if (marketoId) {
    return await axios.post(url, { marketo_marketing_email_id: marketoId }, API_REQUEST_HEADERS(jwt))
      .then(resp => {
        return resp.data
      })
      .catch(err => {
        let errorMsg = '';
        if (err.response) {
          const errBody = err.response.data;
          const rawMsg = errBody.error;
          if (rawMsg.include('Mysql2::Error: Duplicate entry')) {
            errorMsg = 'This email has already been imported.  Please use search by name instead';
          }
        } else {
          errorMsg = err.message;
        }
        throw new Error(errorMsg, { cause: err });
      });
  } else {
    throw new Error('Invalid Marketo Email URL');
  }
};

export const getMarketoEmailAll = async (jwt) => {
  const url = `${API_ENDPOINT}/marketo_marketing_emails`
  return await axios.get(url, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
};

export const getMarketoEmailById = async (jwt, id) => {
  const url = `${API_ENDPOINT}/marketo_marketing_emails/${id}`
  return await axios.get(url, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    });
};

export const scanMarketoEmail = async (jwt, id) => {
  const url = `${API_ENDPOINT}/marketo_marketing_emails/${id}/scan`;
  return await axios.post(url, { marketo_marketing_email_id: id }, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw new Error('Failed to Scan Marketo Email:', { cause: err });
    });
};

export const searchMarketoEmail = async (jwt, keyword) => {
  const url = `${API_ENDPOINT}/marketo_marketing_emails/search`;
  return await axios.post(url, { keyword: keyword }, API_REQUEST_HEADERS(jwt))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    })
};

export const reportVersionData = (reportData) => {
  let versionData = {
    html: {
      links: [],
      images: []
    },
    text: {
      links: []
    }
  };

  if (reportData.hasOwnProperty('email') && reportData.hasOwnProperty('links') && reportData.hasOwnProperty('images')) {
    if (reportData.links.length > 0) {
      versionData = {
        html: {
          links: reportData.links,
          images: reportData.images
        },
        text: {
          links: []
        }
      }
    }
  }

  if (reportData.hasOwnProperty('html') && reportData.hasOwnProperty('text')) {
    versionData = {
      html: {
        links: reportData.html.links,
        images: reportData.html.images
      },
      text: {
        links: reportData.text.links
      }
    }
  }
  return versionData;
};

const EmailAnalyzerService = {
  importMarketoEmail,
  getMarketoEmailAll,
  getMarketoEmailById,
  scanMarketoEmail,
  searchMarketoEmail,
  reportVersionData
};

export default EmailAnalyzerService;
