import { useEffect, useState } from "react";
import TablePagination from "../shared/TablePagination";
import ValidationRuleFormModal from "./ValidationRuleFormModal";
import { createValidationRule, saveValidationRule } from '../../service/ValidationRuleService'

const defaultRuleObject = {
  rule_id: '',
  rule_type: '',
  rule_condition: '',
  rule_value: '',
  rule_action: 'DENY',
  rule_brands: ''
};

const ValidationRuleMainPanel = (props) => {
  const initialRule = props.rule !== null ? props.rule : defaultRuleObject;

  const [selectedRule, setSelectedRule] = useState(initialRule);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [ruleType, setRuleType] = useState('');

  const [searchQuery, setSearchQuery] = useState('');

  const selectRow = (rule) => {
    setSelectedRule(rule);
    setModalIsOpen(true);
  };

  const closeModal = (ruleData) => {
    console.debug('Closing Modal handler with rule data: ', ruleData);
    // const oldState = props.rules;
    if (ruleData.result === 'created') {
      console.debug('CreatedNewValidationRule', ruleData);
      console.debug('CreatedValidationRule.ruleSet', props.rules);
      props.rules.ruleList.push(ruleData);
    }

    if (ruleData.result === 'updated') {
      console.debug('CreatedNewValidationRule', ruleData);
      console.debug('CreatedValidationRule.ruleSet', props.rules);
    }

    setModalIsOpen(false);
  }

  const handleSaveValidationRule = (formData) => {
    // TODO: Handle Validation Save
    console.debug('ValidationRuleMainPanel.handleSaveValidationRule: ', formData);
  };

  const handleNewRuleClick = () => {
    setSelectedRule(defaultRuleObject);
    setModalIsOpen(true)
  };

  const navigateToPage =  (page) => {
    props.onPageChange(page);
  }

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const performSearch = () => {
    console.debug('performSearch: ', searchQuery);
    props.onSearch(searchQuery);
  };

  if (props.rules === null || props.rules === undefined) {
    return 'LOADING'
  } else {
    const { rules } = props;

    

    const activeRows = rules.ruleList.map(item => {

      const excluded = [];

      ['lawpay', 'cpacharge', 'affinipay', 'clientpay', 'mycase', 'casepeer', 'docketwise'].map(b => { 
        const m = item.rule_brands.includes(b);
        if (m === false) {
          excluded.push(b);
        }
      })

      const excludedBrands = excluded.join(', ');

      return (
        <div
          className="grid grid-cols-4 py hover:cursor-pointer hover:bg-info" key={`rule-${item.id}`}
          onClick={ () => selectRow(item) }
        >
          <div className="text-sm">{ item.rule_type }</div>
          <div className="text-sm">{ item.rule_condition }</div>
          <div className="text-sm">{ item.rule_value }</div>
          <div className="text-sm">{ excludedBrands }</div>
        </div>
      )
    });

    const pagingData = rules.pageData ? rules.pageData : null
    const firstPageIndex = ((pagingData.current * pagingData.per_page) - pagingData.per_page);
    const firstIndex = firstPageIndex === 0 ? 1 : firstPageIndex;

    const tmpLastIndex = pagingData.per_page * pagingData.current
    const lastResultItem = tmpLastIndex > pagingData.total_results ? pagingData.total_results : tmpLastIndex
    const lastIndex = pagingData.total_results < pagingData.per_page ? pagingData.total_results : lastResultItem;

    if (isLoading) {
      return (
        <div className="text-3xl">LOADING...</div>
      )
    } else {
      return (
        <div className="m-8">
          <ValidationRuleFormModal isOpen={ modalIsOpen } onClose={ closeModal } onSubmit={ handleSaveValidationRule } rule={ selectedRule } />
          
          <div className="grid grid-cols-2">
            <div><button className="btn btn-primary rounded-md" onClick={ handleNewRuleClick }>Create Rule</button></div>
            <div>
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div className="input-group">
                  <input type="search"
                    id="default-search"
                    placeholder="Search Rule Values"
                    className="float-left flex-1 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700"
                    onChange={ handleSearchInputChange }
                    required
                  
                  />
                  <button className="btn-primary rounded-md float-right px-4" onClick={ performSearch }>
                    <svg className="w-6 h-6 text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                  </button>
                </div>
            </div>
            
          </div>
          <div className="grid grid-cols-2">
            <div>Showing { firstIndex } - { lastIndex } of { pagingData.total_results }</div>
            <div className="text-right">Page { pagingData.current} of { pagingData.total_pages }</div>
          </div>
          <div className="divide-y divide-dotted">
            <div className="grid grid-cols-4 bg-base-content text-base-100">
              <div className="text-lg p-2">Rule Type</div>
              <div className="text-lg p-2">Rule Condition</div>
              <div className="text-lg p-2">Rule Value</div>
              <div className="text-lg p-2">Excluded Brands</div>
            </div>
            <div className="divide-y divide-dotted">
              { activeRows }
            </div>
          </div>

          <TablePagination { ...pagingData } onPageChange={ (page) => navigateToPage(page) } /> 
        </div>
      )
    }
  }
}

export default ValidationRuleMainPanel;
