import { useState } from 'react';

import PromotionLinkForm from '../promotions/forms/promotion-link-form';
import PromotionDocumentList from '../promotions/promotion-document-list';
import PromotionImageList from '../promotions/promotion-image-list';

const DynamicModal = (props) => {


  if (props) {
    const [linkData, setLinkData] = useState(props.data);
    const [docData, setDocData] = useState([]);

    const saveHandler = async (component, payload) => {
      const resp = await props.onSave(component, payload);
      console.debug('DynamicModal.saveHandler.response: ', resp);
      setLinkData()
    }

    const isOpenCss = props.isOpen === true ? 'modal-open' : '';

    const RenderComponent = (props) => {
      if (props.component === 'links') {
        return <PromotionLinkForm { ...linkData } onSave={ saveHandler } onClose={ () => props.onClose() } />
      }
      if (props.component === 'documents') {
        return <PromotionDocumentList { ...props.data } onSave={ saveHandler } />
      }

      if (props.component === 'images') {
        return <PromotionImageList { ...props.data } onSave={ saveHandler } />
      }
    };

    return (
      <div className={ `modal ${isOpenCss}` } id="text-sm">
        <div className="modal-box">
          <h3 className="font-semibold text-xl">Dynamic Modal Here</h3>
          <div className="p-4">
            <RenderComponent { ...props } />
          </div>
        </div>
      </div>
    );
  }
};

export default DynamicModal;
