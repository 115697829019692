import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import CampaignDetailSummary from "../components/campaigns/campaign-detail-summary";
import CampaignPromotionsTable from "../components/campaigns/campaign-promotions-table";
import { fetchCampaign } from '../service/CampaignService';
import { createPromotion, fetchPromotionTypes } from '../service/PromotionService';
import PromoWizard from '../components/promotion-wizard/promo-wizard';

const CampaignDetailPage = (props={}) => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [promoList, setPromotionList] = useState(null);
  const [wizardOpen, setWizardOpen] = useState(false);

  const params = useParams();
  const { authState } = useOktaAuth();

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleCreatePromotion = (data) => {
    console.debug('Handle Promotion Save: ', data);
    createPromotion(authState, data)
      .then(resp => {
        getPromotions();
        setIsLoading(false);
      })
      .catch(err => {
        console.error('CAMPAIGN_')
      })
  }

  const getPromotions = () => {
    return fetchCampaign(authState, params.id)
      .then(resp => {
        console.debug("DETAIL_PAGE_RESPONSE: ", resp);
        setActiveCampaign(resp);
        setPromotionList(resp.promotions);
        // setActiveCampaign(resp);
      })
      .catch(err => {
        console.error('DETAIL_PAGE_ERROR: ', err);
      });
  }

  if (!activeCampaign) {
    getPromotions();
  }

  if (wizardOpen) {
    return <PromoWizard campaign={ activeCampaign } />
  } else {
    return(
      <div className="m-8">
        <CampaignDetailSummary { ...activeCampaign } />
        <CampaignPromotionsTable 
          promos={ promoList }
          createHandler={ () => setWizardOpen(true) }
        />
      </div>
    );
  }
}

export default CampaignDetailPage;
