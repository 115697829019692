import { useState, useEffect } from 'react';
// import { useOutletContext, Link } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import ValidationRuleMainPanel from "../components/validation-rules/ValidationRuleMainPanel";
import ValidationRuleService from '../service/ValidationRuleService';
import MarOpsFlashAlert from '../components/shared/MarOpsFlashAlert';

const ValidationRules = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [rulesList, setRulesList] = useState([]);
  const [activeFilters, setActiveFilters] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();

  // console.debug
  useEffect(() => {
    console.debug('ValidationRules.useEffect.activeFilters: ', activeFilters)
  }, [activeFilters]);

  useEffect(() => {
    console.debug('ValidationRules.useEffect.rulesList', rulesList)
  }, [activeFilters]);

  const fetchRuleSet = async () => {
    setIsLoading(true);
    if (activeFilters === null) {
      const defaultFilters = { rule: '', query: '', page: 1, max: 100 }
      setActiveFilters(defaultFilters);
    }

    return ValidationRuleService.fetchValidationRuleList(authState.accessToken, activeFilters)
      .then(resp => {
        console.debug("RESPONSE: ", resp);
        setRulesList(resp);
        setIsLoading(false);
        return rulesList;
      })
      .catch(err => {
        setErrorMessage(err.message);
        setIsLoading(false);
        return err;
      })
  }

  const filteredRules = async (filter) => {
    const newState = activeFilters;
    newState.rule = filter;
    newState.query = '';
    setActiveFilters(newState);
    const rs = await fetchRuleSet();
    return rs;
  }

  const handlePageChange = async (page) => {
    // setIsLoading(true);
    const filters = activeFilters;
    filters.page = page;
    setActiveFilters(filters);
    const resp = await fetchRuleSet(activeFilters);
    setIsLoading(false);
    return resp;
  }

  const handleSearch = async (query) => {
    // setIsLoading(true);
    const filters = activeFilters;
    filters.rule = '';
    filters.page = 1;
    filters.query = query;
    setActiveFilters(filters);
    const resp = await fetchRuleSet(activeFilters);
    setIsLoading(false);
    return resp;
  };

  if (authState.isAuthenticated === true && rulesList && rulesList.length === 0 && activeFilters === null) {
    fetchRuleSet();
  }

  if (isLoading === true) {
    return (
      <div className="x-center y-center text-4xl">LOADING</div>
    )
  } else {
    return (
      <div className="w-full m-4">
        <div className="grid grid-cols-12">
          <div className="col-span-3 pt-6">
            <div className="w-full pr-8 mr-8">
              <h2 className="text-4xl">Validation Rules</h2>
              <ul className="divide-y divide-dotted py-4">
                <li className="py-2 hover:cursor-pointer hover:bg-base-200" onClick={ () => filteredRules('SERVICE_PROVIDER_DOMAIN') }>Service Provider Domains</li>
                <li className="py-2 hover:cursor-pointer hover:bg-base-200" onClick={ () => filteredRules('EXCLUDED_COUNTRIES') }>Excluded Countries</li>
                <li className="py-2 hover:cursor-pointer hover:bg-base-200" onClick={ () => filteredRules('EXCLUDED_EMAIL_USER') }>Excluded Email Usernames</li>
                <li className="py-2 hover:cursor-pointer hover:bg-base-200" onClick={ () => filteredRules('NO_SEND_TLD') }>Excluded Domain Types</li>
                <li className="py-2 hover:cursor-pointer hover:bg-base-200" onClick={ () => filteredRules('TRIGGER_JOB_TITLE') }>Job Title Flags</li>
                <li className="py-2 hover:cursor-pointer hover:bg-base-200" onClick={ () => filteredRules('TRIGGER_COMPANY_NAME') }>Company Name Flags</li>
              </ul>
            </div>
          </div>
          <div className="col-span-9 pt-6 bordered rounded">
            <ValidationRuleMainPanel 
              isLoading={ isLoading } 
              rules={ rulesList } 
              onPageChange={ (page) => handlePageChange(page) } 
              onSearch={ (query) => handleSearch(query) }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ValidationRules;
