import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldMappingRow from './FieldMappingRow';
import { cleanKeyTransform } from '../../../service/UtilityFunctions';

const UploadStepTwo = (props) => {

  const [attrMapping, setAttrMapping] = useState({});

  useEffect(() => {
    console.debug('STEP2:USE_EFFECT: ', props.fileData)
  }, [props.fileData])
  
  const handleMappingChange = (data) => {
    const newState = attrMapping;
    const key = cleanKeyTransform(data.csv_key);
    newState[key] = data.capdb_key;
    setAttrMapping(newState);
    console.debug('REVISED_STATE.attrMapping:', attrMapping)
  };

  if (props.visible == true && props.fileData != null) {
    const csvFields = props.fileData.uploaded_keys;
    const fieldRows = csvFields.map((item, i) => {
      return (
        <div key={ i }>
          <FieldMappingRow item={ item } onChange={ handleMappingChange } />
        </div>
      );
    });

    return (
      <div>
        <h1 className="text-3xl">Step Two: Map Fields</h1>
        <div className="grid grid-cols-2 h-8">
          <div className="bg-black text-white">Your File</div>
          <div className="bg-black text-white">CAPDB Field</div>
        </div>
        { fieldRows }
        <button className="btn btn-primary mt-6 rounded" onClick={ () => props.onSubmit(attrMapping) }>Save Mappings</button>
      </div>
    )
  }
};

UploadStepTwo.propTypes = {
  visible: PropTypes.bool.isRequired,
  fileData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

UploadStepTwo.defaultProps = {
  visible: false,
  fileData: null,
  onSubmit: () => {},
};

export default UploadStepTwo;
