export const GraphConfigDatabaseActivity = (graphData={}) => {

  console.debug('GRAPH_DATA: ', graphData);
  return {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: 'Customer & Prospect Database Activity'
    },
    xAxis: {
      categories: graphData.date_range
    },
    yAxis: {
      title: {
        text: 'Counts'
      }
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormatter: function() {
        const val = new Intl.NumberFormat('en-US').format(this.y);
        return `${this.series.name} <b>${this.percentage.toFixed(2)}%</b> : (${val})<br />`;
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      },
      series: {
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.y:.0f}'
        }
      }
    },
    series: [
      {
        name: 'Records Created',
        data: graphData.created
      },
      {
        name: 'Changed to Customer',
        data: graphData.converted
      }
    ]
  }
};
