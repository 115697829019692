export const GraphDataSourceConfig = (graphData) => {
  return {
    credits: {
      enabled: false
    },
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Raw Records by Data Source'
    },
    accessibility: {
      announceNewData: {
        enabled: true
      },
      point: {
        valueSuffix: '%'
      }
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },
    tooltip: {
      pointFormatter: function() {
        const val = new Intl.NumberFormat('en-US').format(this.y);
        return `<b>${val}</b>`;
      },
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: false
      }
    },
    series: [{
      name: 'Data Sources',
      colorByPoint: true,
      data: graphData.seriesData
    }]
  };
}
