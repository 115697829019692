import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const MarOpsFlashAlert = (props) => {

  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const hasError = props.message != '' && props.message != null;    
    setVisible(hasError);
  }, [props.message]);

  const onDismiss = () => {
    setErrorMessage('');
    setVisible(false);
  };

  if (errorMessage != '') {
    setVisible(true);
  }

  if (visible != true) {
    return null;
  } else {
    return (
      <div className={ `alert alert-${props.variant} shadow-lg` }>
        <div className="w-full grid grid-cols-3">
          <div>{ props.heading }</div>
          <div>{ props.message }</div>
          <div className="text-right">
            <svg
              className="h-6 w-6 hover:cursor-pointer content-end mr-4" 
              fill="none" 
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={ onDismiss }
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      </div>
    )
  }
};

MarOpsFlashAlert.propTypes = {
  heading: PropTypes.string,
  variant: PropTypes.string,
  message: PropTypes.string
};

MarOpsFlashAlert.defaultProps = {
  heading: '',
  variant: 'info',
  message: ''
};

export default MarOpsFlashAlert;
