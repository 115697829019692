import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { getDomainQueue } from '../service/DomainEnrichmentService';
import UIContextIndicator from '../components/shared/UIContextIndicator';
import DomainEnrichmentTable from '../components/enrichment/DomainEnrichmentTable';

const DomainEnrichmentPage = (props) => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [domainQueue, setDomainQueue] = useState(null);
  const { authState } = useOktaAuth();


  useEffect(() => {
    if (domainQueue != null) {
      console.debug('domainQueue.useEffect: ', domainQueue);
    }
  }, [domainQueue])


  if (domainQueue === null) {
    getDomainQueue(authState.accessToken)
      .then(resp => {
        setDomainQueue(resp);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Unable to fetch domain enrichment queue: ', err);
      });
  }

  const approveDomain = () => {
    console.debug('Mark Domain as invalid');
  }

  const invalidDomain = () => {
    console.debug('Mark Domain as invalid');
  }

  if (isLoading == true) {
    return <UIContextIndicator message="Loading.." />
  } else {
    return (
      <div className="m-4">
        <h1 className="text-3xl">Domain Enrichment</h1>
        <div className="m-0 grid">
          <DomainEnrichmentTable data={ domainQueue } />
        </div>
      </div>
    )
  }
}

export default DomainEnrichmentPage;
