const TablePagination = (props) => {
  if (props === null || props === 'undefined') {
    return ('LOADING');
  } else {

    const totalPages = props.total_pages;
    const pageIndex = props.current - 5 || 0;
    const startIndex = (pageIndex <= 0 ? 1 : pageIndex);
    const midPoint = (startIndex + 5);
    const lastIndex = ((midPoint + 5) > totalPages ? totalPages + 1 : midPoint + 5);

    const pageData = [];

    for(let x=startIndex; x<lastIndex; x++) {
      pageData.push(x);
    }

    const handlePageClick = (page) => {
      console.debug('TODO: handlePageClick: ', page);
      props.onPageChange(page);
    }

    const pagingData = pageData.map((page, index) => {
      const activePageClass = page === props.current ? 'active' : ' btn-secondary'
      return (
        <button
          key={ index }
          className={ `btn ${activePageClass}` }
          onClick={ () => handlePageClick(page) }
        >{ page }</button>
      )
    });

    return (
      <div className="btn-group mt-8 justify-center text-center">
        { pagingData }
      </div>
    )
  }
}

export default TablePagination;
