
export const dateFormat = (inputDate, dateStyle='long', timeStyle='') => {
  if (!inputDate) inputDate = new Date();
  const d = new Date(inputDate);
  const options = { dateStyle: dateStyle };
  if (timeStyle != '') options.timeStyle = timeStyle;

  return new Intl.DateTimeFormat('en-US', options).format(d);
}

export const cleanKeyTransform = (str) => {
  const keyName = str.replace(/ /g, '_').replace(/\W/g, '').toLowerCase();
  return keyName;
}

export const CAPDB_FIELDS = () => {
  return [
    '-- Ignore --',
    'company_name',
    'full_name',
    'first_name',
    'last_name',
    'email_address',
    'phone_number',
    'domain_name',
    'address_1',
    'address_2',
    'city',
    'state_province',
    'postal_code',
    'country_code',
    'job_title',
    'practice_areas',
    'firm_size'
  ]
};


export const formatJobRuntime = (job) => {
  console.debug('calculateRuntime: ', job);
  const startTimestamp = Date.parse(job.task_date_started);
  const stopTimeStamp = Date.parse(job.task_date_stopped) || Date.now();

  const runMs = (stopTimeStamp - startTimestamp).toFixed(2);
  const runSec = (runMs / 1000).toFixed(2);

  let output = '';
  if (runSec < 60) {
    output += runSec + " seconds";
  } else if (runSec < 3600) {
    output += (runSec / 60).toFixed(2) + " minutes";
  } else if (runSec < 86400) {
    output += (runSec / 3600).toFixed(2) + " hours";
  } else if (runSec < 2620800) {
    output += (runSec / 86400).toFixed(0) + " day";
  } else if (runSec < 31449600) {
    output += (runSec / 2620800).toFixed(0) + " month";
  }
  return output;
}
