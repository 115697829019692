import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import CardEmailSummary from './cards/CardEmailSummary';
import CardLinkSummary from './cards/CardLinkSummary';
import CardImageSummary from './cards/CardImageSummary';
import CardPreviewSummary from './cards/CardPreviewSummary';
import MarOpsFlashAlert from '../shared/MarOpsFlashAlert';
import EmailAnalyzerService from '../../service/EmailAnalyzerService';
import UIContextIndicator from '../shared/UIContextIndicator';


const EmailAnalyzerPanelDetail = (props) => {
  const { email, report } = props;
  const emailId = (email && email.id ? email.id : null);

  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryTabActive, setSummaryTabActive] = useState(true);
  const [linksTabActive, setLinksTabActive] = useState(false);
  const [imageTabActive, setImageTabActive] = useState(false);
  const [previewTabActive, setPreviewTabActive] = useState(false);
  const [activeEmail, setActiveEmail] = useState(null);
  const [activeReport, setActiveReport] = useState(null);
  const [flashContext, setFlashContext] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);

  useEffect(() => {
      setActiveEmail(email);
  }, [email])

  useEffect(() => {
    if (activeReport != null) {
      setActiveReport(report);
    }
  }, [report])

  const handleTabSelect = (event) => {
    const target = event.target.getAttribute('href');

    setSummaryTabActive(false);
    setLinksTabActive(false);
    setImageTabActive(false);
    setPreviewTabActive(false);

    if (target.includes('summary')) setSummaryTabActive(true);
    if (target.includes('links'))   setLinksTabActive(true);
    if (target.includes('image'))   setImageTabActive(true);
    if (target.includes('preview')) setPreviewTabActive(true);
  }

  const handleScanRequest = async () => {
    setIsLoading(true);
    try {
      const resp = await EmailAnalyzerService.scanMarketoEmail(authState.accessToken, email.id);        
      setActiveEmail(resp.obj);
      setIsLoading(false);
    } catch(err) {
      if (err.code === 'ERR_BAD_REQUEST') {
        // setAuthContext(false);
      } else {
        setFlashContext('error')
        setFlashMessage(err.message);
      }
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <UIContextIndicator message="Scanning..." />
  } else {
    if (props.email === null || typeof(props.email) === 'undefined') {
      return (
        <div className="mt-2">
          <p>This tool is used to verify the content and structure of outgoing Marketo emails.</p>
        </div>
      );
    } else {
      return (
        <div className="mt-2">

          <MarOpsFlashAlert variant={ flashContext } message={ flashMessage } />

          <div className="grid-cols-2">
            <button className="btn btn-primary float-right" onClick={ handleScanRequest }>Scan</button>
          </div>

          <div className="tabs mt-4">
            <Link to="#tabs-summary" className={ `tab tab-lifted text-xl px-4 ${summaryTabActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>Summary</Link>
            <Link to="#tabs-links" className={ `tab tab-lifted text-xl px-4 ${linksTabActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>Links</Link>
            <Link to="#tabs-images" className={ `tab tab-lifted text-xl ${imageTabActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>Images</Link>
            <Link to="#tabs-preview" className={ `tab tab-lifted text-xl ${previewTabActive ? 'tab-active' : ''}` } onClick={ handleTabSelect }>Preview</Link>
          </div>

          <div className="tab-content" id="tabs-tabContent">
            <div className={ `tab-pane p-2 pt-6 ${summaryTabActive ? 'tab-active' : 'hidden'}`} id="tabs-summary" role="tabpanel">
              <CardEmailSummary email={ activeEmail }/>
            </div>
            <div className={ `tab-pane p-2 pt-6 ${linksTabActive ? 'tab-active' : 'hidden'}`} id="tabs-links" role="tabpanel">
              <CardLinkSummary { ...report } />
            </div>
            <div className={ `tab-pane p-2 pt-6 ${imageTabActive ? 'tab-active' : 'hidden'}`} id="tabs-images" role="tabpanel">
              <CardImageSummary { ...report } />
            </div>
            <div className={ `tab-pane p-2 pt-6 border-solid border-b-1 ${previewTabActive ? 'tab-active' : 'hidden'}`} id="tabs-preview" role="tabpanel">
              <CardPreviewSummary { ...report } />
            </div>
          </div>
        </div>
      )
    }
  }
}

export default EmailAnalyzerPanelDetail;
