import { useState, useEffect } from "react"
import PropTypes from 'prop-types';

const DropDownMenu = (props) => {

  const listItems = props.options.map((opt, i) => {
    return (
      <option key={ i } value={ opt }>{ opt }</option>
    )
  });

  const handleChange = (event) => {
    console.debug('DropDownMenu.handleChange: ', event.target.value)
    props.onChange(event);
  }

  return (
    <div className="dropdown w-full">
      <select
        name={ props.item }
        className="select w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary"
        onChange={ (handleChange) }>
        { listItems }
      </select>
    </div>
  )
}

DropDownMenu.propTypes = {
  item: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

DropDownMenu.defaultProps = {
  item: {},
  options: [],
  onChange: () => { console.debug('You must implement the DropDownMenu.onChange method')}
};

export default DropDownMenu;
