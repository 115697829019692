import { useState, useEffect } from "react";
import UIContextIndicator from "../shared/UIContextIndicator";
import DashboardCard from '../shared/DashboardCard';
import { useOktaAuth } from '@okta/okta-react';

import { getDomainQueue, updateEnrichmentResult } from '../../service/DomainEnrichmentService';
import DomainEnrichmentTableRow from "./DomainEnrichmentTableRow";

const DomainEnrichmentTable = (props) => {

  const [activeFilter, setActiveFilter] = useState('pending_approval');
  const [reActiveList, setReActiveList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [pendingCrawlTotal, setPendingCrawlTotal] = useState(0);
  const [totalCaptured, setTotalCaptured] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalDeclined, setTotalDeclined] = useState(0);

  const { authState } = useOktaAuth();

  useEffect(() => {
    const queues = props.data.queues || [];
    const stats = props.data.stats || { total: 0, pending: 0, approved: 0, declined: 0};
    setReActiveList(queues);
    setTotalCaptured(stats.total);
    setPendingCrawlTotal(stats.pending_crawl)
    setTotalPending(stats.pending_approval);
    setTotalApproved(stats.approved);
    setTotalDeclined(stats.declined);
  }, [props.queues]);

  const applyListFilter = async (event) => {
    const filter = event.target.value;
    setActiveFilter(filter);
    const newQueue = await getDomainQueue(authState.accessToken, filter);
    setReActiveList(newQueue.queues);
  };

  const tableRows = reActiveList.map((item, i) => {
    
    const handleApproveClick = () => {
      item.verified_status = 1;

      updateEnrichmentResult(authState.accessToken, item)
        .then(resp => {
          console.debug('Response from updateEnrichmentResult: ', resp);
        })
        .catch(err => {
          console.error('Error approving item: ', err.message);
        });

      const newList = reActiveList.filter(origItem => {
        return origItem.id !== item.id
      });

      const newApproved = totalApproved + 1;
      const newPending = totalPending - 1;

      setReActiveList(newList);
      setTotalApproved(newApproved);
      setTotalPending(newPending);
      return newList;
    };
  
    const handleInvalidClick = () => {
      
      item.verified_status = 0;
      updateEnrichmentResult(authState.accessToken, item)
        .then(resp => {
          console.debug('Response from updateEnrichmentResult: ', resp);
        })
        .catch(err => {
          console.error('Error approving item: ', err.message);
        });

      const newList = reActiveList.filter(origItem => {
        return origItem.id !== item.id
      });
      const newPending = totalPending - 1;
      const newDeclined = totalDeclined + 1;
      setReActiveList(newList);
      setTotalDeclined(newDeclined);
      setTotalPending(newPending);
    };

    const handleEditClick = (event) => {
      console.debug('TABLE.handleEditClick: ', event);
      setIsEditing(true);
    }

    const handleSaveClick = async (item) => {
      console.debug('Table.handleSaveClick: ', item.verified_status);

      item.verified_status = item.verified_status === true ? 1 : 0;

      updateEnrichmentResult(authState.accessToken, item)
        .then(resp => {
          console.debug('Response from updateEnrichmentResult: ', resp.data);
        })
        .catch(err => {
          console.error('Error saving item: ', err.message);
        });

      const newList = reActiveList.filter(origItem => {
        return origItem.id !== item.id
      });

      const newDeclined = item.verified_status == false ? totalDeclined + 1 : totalDeclined - 1;
      const newApproved = item.verified_status == true ? totalApproved + 1 : totalApproved - 1;
      setReActiveList(newList);
      setTotalApproved(newApproved);
      setTotalDeclined(newDeclined);
    }

    return (
      <DomainEnrichmentTableRow
        key={ i }
        data={ item }
        onApproved={ handleApproveClick }
        onInvalid={ handleInvalidClick }
        onSave={ handleSaveClick }
      />
    );
  });

  if (reActiveList == null) {
    return <UIContextIndicator message="Loading..." />
  } else {
    return (
      <div>

        <div className="grid grid-cols-6 gap-4 my-4">
          <div>
            <label htmlFor="verified_status" className="text-sm">Filter by Status:</label><br />
            <select className="select select-bordered" name="verified_status" onChange={ applyListFilter }>
              <option value="pending_approval">Pending Approval ({ totalPending })</option>
              <option value="approved">Approved Domains ({ totalApproved })</option>
              <option value="declined">Declined Domains ({ totalDeclined })</option>
              <option value="pending_crawl">Pending Crawl ({ pendingCrawlTotal })</option>
              <option value="all">All ({ totalCaptured })</option>
            </select>
          </div>
          <DashboardCard title="Pending Crawl" value={ pendingCrawlTotal } />
          <DashboardCard title="Pending" value={ totalPending } />
          <DashboardCard title="Approved" value={ totalApproved } />
          <DashboardCard title="Declined" value={ totalDeclined } />
          <DashboardCard title="Total Acquired" value={ totalCaptured } />
        </div>

        <table className="w-full">
          <thead className="bg-slate-300 text-slate-800 bold">
            <tr className="text-lg text-center">
              <td>Batch #</td>
              <td>Data Point</td>
              <td>Enriched Value</td>
              <td>Source Query</td>
              <td>Source Service</td>
              <td>Sourced From</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            { tableRows }
          </tbody>
        </table>
      </div>
    )
  }
};

export default DomainEnrichmentTable;
