import { useState, useEffect } from 'react';
import FormPromoType from './form-promo-type';

const PromoWizard = (props) => {

  const [promoTypeId, setPromoTypeId] = useState(null);
  const [activeStep, setActiveStep] = useState(null);

  const [promoTitle, setPromoTitle] = useState(null);
  const [promoDescription, setPromoDescription] = useState(null);
  const [launchDate, setLaunchDate] = useState(null);
  const [expireDate,setExpireDate] = useState(null);
  const [includesGift, setIncludesGift] = useState(null);

  const handleTypeSelection = (promoType) => {
    console.debug('PromoWizard.handleTypeSelection: ', promoType);
    setPromoTypeId(promoType);
  }

  const onSaveCallback = (data) => {  
    console.debug('PromoWizard.onSaveCallback ', data);
  };

  return (
    <div className="m-4">
      <h1 className='text-3xl'>Create Promotion Wizard</h1>

      <div className='form-group my-4'>
          <label htmlFor='title' className="pr-4 font-semibold">Promotion Title</label>
          <input type='text' name='title' className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setPromoTitle(event.target.value) } />
        </div>

        <div className="form-group my-4">
          <label htmlFor="description" className="pr-4 font-semibold">Promotion Description</label>
          <textarea name="description" onChange={ (event) => setPromoDescription(event.target.value)}></textarea>
        </div>

        <div className='form-group my-4 grid grid-cols-2 gap-4'>
          <div>
            <label htmlFor='launchg_date'>Launch Date</label>
            <input type='date' name='launch_date'  className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setLaunchDate(event.target.value) } />
          </div>
          <div>
            <label htmlFor='expire_date'>Expires Date</label>
            <input type='date' name='expire_date'  className='input w-full border-base-300 rounded-lg focus:outline-none focus:border-secondary' onChange={ (event) => setExpireDate(event.target.value) } />
          </div>
        </div>

      <FormPromoType
        onSaveCallback={ (data) => onSaveCallback(data) }
        onSelectType={ (type) => handleTypeSelection(type) }
        activeComponent={ activeStep }
      />
    </div>
  )
}

export default PromoWizard;
