import axios from 'axios';
import {
  API_ENDPOINT, 
  API_REQUEST_HEADERS
} from './ServiceHelpers';

export const fetchCampaignEntries = async (authState) => {
  console.debug('fetchCampaignEntries: ', authState);
  return axios.get(`${API_ENDPOINT}/campaigns`, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      throw err;
    })
}

export const fetchCampaign = async (authState, id) => {
  console.debug('fetchCampaign', authState.accessToken);
  return axios.get(`${API_ENDPOINT}/campaigns/${id}`, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      console.debug("SERVER_RESPONSE: ", resp.data);
      return resp.data;
    })
    .catch(err => {
      throw new Error('Unable to fetch campaigns', { cause: err });
    })
}

export const createCampaign = async (authState, data) => {
  return axios.post(`${API_ENDPOINT}/campaigns`, data, API_REQUEST_HEADERS(authState.accessToken))
    .then(resp => {
      console.debug('SERVER_RESPONSE_FROM_CREATE: ', resp);
      return resp.data;
    })
    .catch(err => {
      console.error('ERROR PROCESSING REQUEST: ', err.message);
      throw new Error('Unable to create campaign', { cause: err });
    })
};

const CampaignService = {
  fetchCampaignEntries,
  fetchCampaign
};

export default CampaignService;
