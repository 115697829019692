const NotificationSubscriptions = (props) => {

  console.debug('NotificationSubscriptions.props: ', props);

  return (
    <div>
      <h1>NotificationSubscriptions </h1>
    </div>
  )
}

export default NotificationSubscriptions;
