import { useEffect, useState } from 'react';
import { dateFormat } from '../../service/UtilityFunctions';


const PromotionUrlList = (props) => {

  const [urlRows, setUrlRows] = useState([])

  useEffect(() => {
    if (props.links != null) {
      console.debug('IMAGES_USE_EFFECT: ', props.links);
      const rows = props.links.map(item => {

        console.debug("ITEM: ", item);
        return (
          <tr key={ item.id }>
            <td>{ item.link_content }</td>
            <td>{ item.link_href }</td>
            <td>{ item.utm_campaign }</td>
            <td>{ item.utm_source }</td>
            <td>{ item.utm_medium }</td>
            <td>{ item.utm_content }</td>
            <td>{ item.partner_token }</td>
            <td>{ item.promo_token }</td>
            <td>{ item.demand_gen }</td>
            <td>{ dateFormat(item.created_at) }</td>
            <td>{ dateFormat(item.updated_at) }</td>
            <td>
              <button className="btn btn-xs btn-primary" onClick={ () => handleRowClick(item) }>Edit</button>
            </td>
          </tr>
        )
      });
      setUrlRows(rows);
    } else {
      const noContent = () => {
        return (
          <tr>
            <td colSpan={ 6 }>This Promotion has no links attached</td>
          </tr>
        )
      }
      setUrlRows(noContent());
    }
  }, [props.links]);

  const initAddLink = () => {
    console.debug('Open modal to add attach image.')
  }

  const handleRowClick = (item) => {
    console.debug('handleRowClick: ', item);

    return props.onEdit('links', item);
  }

  return (
    <div className="my-4">
      <div className="grid grid-cols-2 bg-slate-800 text-white px-2 p-1">
        <div><h1 className="text-xl pt-2">Promotion Links</h1></div>
        <div className="text-right py-2 pr-2"><button className="btn btn-sm btn-primary" onClick={ initAddLink }>Add Link</button></div>
      </div>
      <div>
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-slate-500 text-white p-2">
              <td className="p-2">Link Text</td>
              <td>Link URL</td>
              <td>utm_campaign</td>
              <td>utm_source</td>
              <td>utm_medium</td>
              <td>utm_content</td>
              <td>Partner Token</td>
              <td>Promo Token</td>
              <td>Demand Gen Token</td>
              <td>Created At</td>
              <td>Updated At</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            { urlRows }
          </tbody>
        </table>
      </div>
    </div>
  )

}

export default PromotionUrlList;
