import { useState, useEffect } from 'react';

const DomainEnrichmentTableRow = (props) => {

  const item = { ...props.data };
  const [isEditing, setIsEditing] = useState(false);

  const handleApproveClick = async (event) => {
    console.debug('handleApprovedClick: ', item);
    return await props.onApproved(item);
  }

  const handleInvalidClick = async (event) => {
    event.preventDefault();
    console.debug('handleInvalidClick: ', item);
    return await props.onInvalid(item);
  }

  const handleEditClick = () => {
    setIsEditing(true);
    console.debug('handleEditClick: ', item);
    return;
  }

  const handleSaveClick = async () => {
    const newStatus = !item.verified_status;
    item.verified_status = newStatus;
    setIsEditing(false);
    return await props.onSave(item);
  }

  const handleCancelClick = () => {
    setIsEditing(false);
    return;
  }

  const enqueueButtons = () => {
    return (
      <div>Pending Crawl</div>
    )
  };

  const pendingButtons = () => {
    return (
      <div className="grid grid-cols-2">
        <div><button className="btn btn-xs btn-success" onClick={ handleApproveClick }>Approve</button></div>
        <div><button className="btn btn-xs btn-error" onClick={ handleInvalidClick }>Invalid</button></div>
      </div>
    );
  };

  const editButtons = (status) => {
    return (
      <div className="grid grid-cols-2">
        <div>{ status } by { item.verified_user }</div>
        <div><button className="btn btn-xs btn-info" onClick={ handleEditClick }>Edit</button></div>
      </div>
    );
  }

  const saveButtons = (status) => {
    const inverseStatus = status == 'Approved' ? 'Invalid' : 'Approved';
    return (
      <div className="grid grid-cols-2">
        <div>Confirm change to: { inverseStatus }</div>
        <div>
          <button className="btn btn-xs btn-success" onClick={ handleSaveClick }>Confirm</button>
          <button className="btn btn-xs btn-info mx-4" onClick={ handleCancelClick }>Cancel</button>
        </div>
      </div>
    )
  }

  const statusButtons = () => {
    if (item.verified_status == null) {
      if (item.result_raw !== null && item.result_value !== null) {
        return pendingButtons();
        
      } else {
        return enqueueButtons();
      }
    } else {
      const status = item.verified_status ? 'Approved' : 'Declined';
      if (isEditing === true) {
        return saveButtons(status)
      } else {
        return editButtons(status)
      }
    }
  }

  // setActiveButtons(statusButtons());

  return (
    <tr className={ `divide divide-dotted text-center hover:bg-blue-200 enrichment-${item.id}`} key={ item.id }>
      <td>{ item.enrichment_job_id }</td>
      <td>{ item.data_point }</td>
      <td>{ item.result_value || '-' }</td>
      <td>{ item.source_query }</td>
      <td>{ item.source_service }</td>
      <td>{ item.source_from }</td>
      <td>
        { statusButtons() }
      </td>
    </tr>
  );

}

export default DomainEnrichmentTableRow;
