import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import EmailPromotionForm from "./forms/email-promo-form";
import WebsitePromoForm from "./forms/website-promo-form";
import SocialPromoForm from "./forms/social-promo-form";
import PrintPromoForm from "./forms/print-promo-form";
import AdNetworkPromoForm from "./forms/ad-network-promo-form";

const FormPromoType = (props) => {

  // console.debug('FormPromoType: ', props);

  const params = useParams();
  const [promoTypeId, setPromoTypeId] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [webTab, setWebTab] = useState('');
  const [socialTab, setSocialTab] = useState('');
  const [emailTab, setEmailTab] = useState('');
  const [printTab, setPrintTab] = useState('');
  const [paidTab, setPaidTab] = useState('');

  const activeClassName = 'bg-blue-600';

  const handleSave = (promo) => {
    console.debug('FormPromoType.handleSave.promo ', promo);

    const reqData = {
      promo_type_id: promoTypeId
    }

    props.onSaveCallback(reqData);
  };

  const handleTypeSelection = (value) => {

      setWebTab('');
      setSocialTab('');
      setEmailTab('');
      setPrintTab('');
      setPaidTab('');

      switch(value) {
        case 'web':
          setActiveComponent(<WebsitePromoForm onSave={ handleSave } />);
          setWebTab(activeClassName);
          setPromoTypeId(4)
          break;
        case 'social':
          setActiveComponent(<SocialPromoForm onSave={ handleSave } />);
          setSocialTab(activeClassName);
          setPromoTypeId(2)
          break;
        case 'email':
          setActiveComponent(<EmailPromotionForm onSave={ handleSave } />)
          setEmailTab(activeClassName);
          setPromoTypeId(1)
          break;
        case 'print':
          setActiveComponent(<PrintPromoForm onSave={ handleSave } />)
          setPrintTab(activeClassName);
          setPromoTypeId(7)
          break;
        case 'paid':
          setActiveComponent(<AdNetworkPromoForm onSave={  handleSave } />)
          setPaidTab(activeClassName);
          break;
        default:
          setActiveComponent('');
          break;
      }

      props.onSelectType(value);
  }

  return (
    <div className="m-4">
      <div>What kind of promotion are you running</div>
      <div className="grid grid-cols-5 gap-4">
        <div id="website" className={ `border border-slate-800 text-xl text-center hover:cursor-pointer p-4 ${webTab}` } onClick={ () => handleTypeSelection('web') }>Website Banners & Popup</div>
        <div id="social" className={ `border border-slate-800 text-xl text-center hover:cursor-pointer p-4 ${socialTab}` } onClick={ () => handleTypeSelection('social') }>Social Media</div>
        <div id="email" className={ `border border-slate-800 text-xl text-center hover:cursor-pointer p-4 ${emailTab}` } onClick={ () => handleTypeSelection('email') }>Email</div>
        <div id="print" className={ `border border-slate-800 text-xl text-center hover:cursor-pointer p-4 ${printTab}` } onClick={ () => handleTypeSelection('print') }>Print Media</div>
        <div id="paid" className={ `border border-slate-800 text-xl text-center hover:cursor-pointer p-4 ${paidTab}` } onClick={ () => handleTypeSelection('paid') }>Paid Ad Network</div>
      </div>

      { activeComponent }
      
    </div>
  )
}



export default FormPromoType;
